export const About = {
  type: "FeatureCollection",
  features: [
    {
      type: "Feature",
      properties: {
        id: 1,
        content: "Where I was born",
        year: "1991",
        location: "Seoungnam-si, South Korea",
      },
      geometry: {
        type: "Point",
        coordinates: [127.13949773772823, 37.44461691042772],
      },
    },
    {
      type: "Feature",
      properties: {
        id: 2,
        content: "Early childhood in France",
        year: "1991-1995",
        location: "Paris, France",
      },
      geometry: {
        type: "Point",
        coordinates: [2.152249115523064, 48.94786284320973],
      },
    },
    {
      type: "Feature",
      properties: {
        id: 3,
        content: "Early childhood in Korea",
        year: "1996-1999",
        location: "Seongnam-si, Korea",
      },
      geometry: {
        type: "Point",
        coordinates: [127.1163472017164, 37.37633827318928],
      },
    },
    {
      type: "Feature",
      properties: {
        id: 4,
        content: "Elementary School",
        year: "1999-2004",
        location: "Nowon-gu, Seoul, Korea",
      },
      geometry: {
        type: "Point",
        coordinates: [127.08188459713777, 37.62412828359964],
      },
    },
    {
      type: "Feature",
      properties: {
        id: 5,
        content: "Middel School",
        year: "2004-2006",
        location: "Gangnam-gu, Seoul, Korea",
      },
      geometry: {
        type: "Point",
        coordinates: [127.05014827862077, 37.48682648902764],
      },
    },
    {
      type: "Feature",
      properties: {
        id: 6,
        content: "High School",
        year: "2007-2010",
        location: "Gangnam-gu, Seoul, Korea",
      },
      geometry: {
        type: "Point",
        coordinates: [127.04134066742336, 37.52072575888297],
      },
    },
    {
      type: "Feature",
      properties: {
        id: 7,
        content: "Hongik University BArch",
        year: "2011-2016",
        location: "Mapo-gu, Seoul, Korea",
      },
      geometry: {
        type: "Point",
        coordinates: [126.92534492660307, 37.55152033778237],
      },
    },
    {
      type: "Feature",
      properties: {
        id: 8,
        content: "Exchange Student at ESA",
        year: "2013",
        location: "Paris, France",
      },
      geometry: {
        type: "Point",
        coordinates: [2.330782998219669,48.83761294366766],
      },
    },
    {
      type: "Feature",
      properties: {
        id: 9,
        content: "Internship at Shigeru Ban Office",
        year: "2014",
        location: "Tokyo, Japan",
      },
      geometry: {
        type: "Point",
        coordinates: [139.65516620810737, 35.662623815628805],
      },
    },
    {
      type: "Feature",
      properties: {
        id: 10,
        content: "Service at Air Force",
        year: "2017-2019",
        location: "Seongnam-si, Korea",
      },
      geometry: {
        type: "Point",
        coordinates: [127.11101206348003, 37.44442834138417],
      },
    },
    {
      type: "Feature",
      properties: {
        id: 10,
        content: "Urban studies at Urban Operations",
        year: "2016-2017, 2019-2022",
        location: "Mapo-gu, Seoul, Korea",
      },
      geometry: {
        type: "Point",
        coordinates: [126.92631526234338, 37.55507409831389],
      },
    },
    {
      type: "Feature",
      properties: {
        id: 10,
        content: "Urban Spatial Analytics Master at Upenn",
        year: "2022-2023",
        location: "Pennsylvania, United States",
      },
      geometry: {
        type: "Point",
        coordinates: [-75.19277320696162, 39.95211184721683],
      },
    },
  ],
};

