import React from "react";
import ImageGrid from './DataAnalysisGrid';
import MapData from "./MapData"


function DataAnalysis() {
    return (
        <>
            <MapData />

            <div className="container-mainpage">
                <div className="contents-line"> 
                        <a href ="#Data-Analysis" className="contents-line-text" id="Data-Analysis"> 🞇 Data Analysis / Visualization </a>
                </div>
                <section className="data-image" >   
                    <ImageGrid />
                </section>
            </div>
        </>
    )
}

export default DataAnalysis;
