import React, { useState } from "react";
import ImageViewer from "../components/ImageViewer";
import { useEffect } from "react";



function HomePricePrediction() {
    const [showImageViewer, setShowImageViewer] = useState(false);
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);

    // useEffect(() => {
    //     document.body.style.backgroundColor = "#061c40";
    //     return () => {
    //       document.body.style.backgroundColor = null;
    //     };
    //   }, []);
  
    const images = [];
    for (let i = 1; i <= 20; i++) {
      images.push(`/img/06/${i}.png`);
    }
      
    const handleImageClick = (index) => {
      setSelectedImageIndex(index);
      setShowImageViewer(true);
    };
  
    const handleCloseImageViewer = () => {
      setShowImageViewer(false);
    };
    
    return (
        <div className="project-container">
            <section className="project">
                <div className="project-header">
                    <div className="project-header-title">
                        <div className="contents-line">
                            <div className="line-10"></div>
                        </div>
                        <div className="project-main-title">
                            Homeprice Prediction in Mecklenburg
                        </div>
                    </div>
                    <div className="project-header-year">
                        2022
                        <br></br>
                        <div className="proejct-category">
                            Data Analysis : R
                        </div>
                    </div>
                </div>
                <div className="project-info">
                    <div className="project-description">
                        <a className="project-link" href="/html/Housing_Price_Prediction.html">Link to markdown</a>
                        <div className="contents-line">
                            <div className="line-10"></div>
                        </div>
                        <div className="project-detail-description">
                            <p className="project-detail-description">
                            The aim of this project is to develop a model for predicting house prices in Mecklenburg, NC, that can be generalized to other datasets. By utilizing local knowledge and about 40 variables, the model is designed to depict the amenities assumed to have a relationship with housing prices. The correlation matrix and hedonic model are generated by filtering out negligible data across variables. This project has significant implications as housing prices have a profound impact on communities and can segregate residents by class and race. Furthermore, the public sector also needs to be careful, as many home-related taxes, such as property and acquisition taxes, are levied through these predicted prices.
                            </p>
                            <p className="project-detail-description">
                            Data engineering presents a complex task for data scientists. The first obstacle in accurately predicting housing prices is determining which variables to include in the model. Moreover, geographic factors cannot be ignored in this model as they greatly influence community proximity. Understanding the boundaries of the community requires a wide range of expertise and experience, which can diminish the strength of correlation across specific communities. Therefore, the challenge lies in creating a model that can accurately capture the community context and provide generalizability across different areas.
                            </p>
                            <p className="project-detail-description">
                            <p className="project-detail-description">
                            Data engineering presents a complex task for data scientists. The first obstacle in accurately predicting housing prices is determining which variables to include in the model. Moreover, geographic factors cannot be ignored in this model as they greatly influence community proximity. Understanding the boundaries of the community requires a wide range of expertise and experience, which can diminish the strength of correlation across specific communities. Therefore, the challenge lies in creating a model that can accurately capture the community context and provide generalizability across different areas.
                            </p>                            </p>
                        </div>
                    </div>
                    <div className="project-info-credit">
                        UPENN MUSA
                        <br></br>
                        Minwook Kang
                        <br></br>
                        <br></br>
                        PROFESSOR
                        <br></br>
                        Elizabeth Delmelle<br></br>
                        Michael Fichman<br></br>
                        Matt Harris
                    </div>
                </div>
                <div>
                {images.map((image, index) => (
                    <img className="project-image" src={image} alt={`Image ${index}`} onClick={() => handleImageClick(index)}  />
                ))}
                {showImageViewer && (
                    <ImageViewer images={images} selectedIndex={selectedImageIndex} onClose={handleCloseImageViewer} />
                )}
                </div>


                
            </section>
        </div>
    );
}

export default HomePricePrediction;
