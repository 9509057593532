import React from "react";

function Footer() {
    return (
        <>
            <footer>
                <div className="footer-mail">
                    <p className= "footer-mail-item">mintheworld.official@gmail.com</p>
                    <p className= "footer-mail-item">kmu973@upenn.edu</p>
                </div>
                <div className="footer-sns">
                    <a className= "footer-sns-item" href ="https://github.com/kmu973?tab=repositories"> Github </a>
                    <a className= "footer-sns-item" href ="https://www.linkedin.com/in/minwook-kang-1aa2b724b/"> Linkdin </a>
                </div>
                
            </footer>
        </>

    )
}

export default Footer;