import React, { useState } from "react";
import ImageViewerWide from "../components/ImageViewerWide";
import { useEffect } from "react";


function JeonjuLibrary() {
    const [showImageViewer, setShowImageViewer] = useState(false);
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);

    // useEffect(() => {
    //     document.body.style.backgroundColor = "#968A83";  //set the background color
    //     return () => {
    //       document.body.style.backgroundColor = null;
    //     };
    //   }, []);
  
    const images = [];
    for (let i = 1; i <= 1; i++) {
      images.push(`/img/Design/02_Jeonju/${i}.jpg`);
    }
      
    const handleImageClick = (index) => {
      setSelectedImageIndex(index);
      setShowImageViewer(true);
    };
  
    const handleCloseImageViewer = () => {
      setShowImageViewer(false);
    };
    
    return (
        <div className="project-container">
            <section className="project">
                <div className="project-header">
                    <div className="project-header-title">
                        <div className="contents-line">
                            <div className="line-10"></div>
                        </div>
                        <div className="project-main-title">
                            Jinju Public Library
                        </div>
                    </div>
                    <div className="project-header-year">
                        2021
                        <br></br>
                        <div className="proejct-category">
                            Architecture <br></br>
                            Competition <br></br>
                            Honorable Mention
                        </div>
                    </div>
                </div>
                <div className="project-info">
                    <div className="project-description">
                        <a className="project-link" href="http://www.jinju-competition.com/ko_kr/%ea%b3%b5%ec%a7%80%ec%82%ac%ed%95%ad/?uid=70&mod=document#kboard-document">Link to Related Site</a>
                        <div className="contents-line">
                            <div className="line-10"></div>
                        </div>
                        <div>
                            <p className="project-detail-description">
                            The Jinjoo Public Library is a remarkable project that won an international competition. Situated in the mountains, the plan was carefully crafted to accommodate the slope while providing direct access to the library from the road. The lower level features a lobby that serves as a shared childcare and care center for local residents, as well as a meeting place. The library's elevation facing the apartments was designed to minimize visual interference, and it is integrated with Jangjae Park, with a green rooftop that extends the park's greenery.
                            </p>
                            <p className="project-detail-description">
                            The library's tiered form conforms to the natural topography, with bookshelves arranged on top of the tiers. This design allows the interior space to form a landscape of books, referred to as a "bookscape." The quiet programs are located at the deep and low levels to create an intimate environment, while the front section offers interconnected views. The arrangement of the bookshelves provides diversity to the open space, with some functioning independently and others reinforcing intimacy in the deep areas.
                            </p>
                            <p className="project-detail-description">
                            The library's front multi-purpose exhibition hall and lobby serve as a plaza, accommodating various activities of the public library. The architecturized shelving further adds diversity to the open space, making it an ideal environment for learning, relaxation, and community interaction. The Jinjoo Public Library is a testament to how architecture can be designed to respond to its natural surroundings while serving the needs of the community it serves.
                            </p>
                        </div>
                    </div>
                    <div className="project-info-credit">
                        URBAN OPERATIONS <br></br>
                        Lee Janghwan<br></br>
                        Kang Minwook<br></br>
                        <br></br>
                        STUDIO IN LOCO <br></br>
                        Kang Seunghyun<br></br>
                        Kim Naun <br></br>
                        Lim Hyungjun
                    </div>
                </div>
                <div>
                {images.map((image, index) => (
                    <img className="project-image project-image-wide" src={image} alt={`Image ${index}`} onClick={() => handleImageClick(index)}  />
                ))}
                {showImageViewer && (
                    <ImageViewerWide
    images={images}
    selectedIndex={selectedImageIndex}
    onClose={handleCloseImageViewer}
    imageWidth="85%"
  />
                )}
                </div>


                
            </section>
        </div>
    );
}

export default JeonjuLibrary;
