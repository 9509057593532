import L from "leaflet";
import dataanalysisPng from "./../images/Icon-05.png"

// Making custom Icon
const LeafIcon = L.Icon.extend({
    options:{
        iconSize: [25, 25],
        iconAnchor: [13, 10],
        tooltipAnchor: [0, 0]
    }
})

export const dataanalysisIcon = new LeafIcon({iconUrl: dataanalysisPng});