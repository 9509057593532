import React, { useState } from "react";
import ImageViewerWide from "../components/ImageViewerWide";
import { useEffect } from "react";


function RemoteSensingKR() {
    const [showImageViewer, setShowImageViewer] = useState(false);
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);

    // useEffect(() => {
    //     document.body.style.backgroundColor = "#103D5A";  //set the background color
    //     return () => {
    //       document.body.style.backgroundColor = null;
    //     };
    //   }, []);
  
    const images = ["/img/07/1.gif"];
    for (let i = 1; i <= 16; i++) {
      images.push(`/img/07/${i}.png`);
    }
      
    const handleImageClick = (index) => {
      setSelectedImageIndex(index);
      setShowImageViewer(true);
    };
  
    const handleCloseImageViewer = () => {
      setShowImageViewer(false);
    };
    
    return (
        <div className="project-container">
            <section className="project">
                <div className="project-header">
                    <div className="project-header-title">
                        <div className="contents-line">
                            <div className="line-10"></div>
                        </div>
                        <div className="project-main-title">
                            Urbanization of Korean Peninsular
                        </div>
                    </div>
                    <div className="project-header-year">
                        2022
                        <br></br>
                        <div className="proejct-category">
                            Data Analysis : ArcGIS + Deeplearning.pkg
                        </div>
                    </div>
                </div>
                <div className="project-info">
                    <div className="project-description">
                        <a className="project-link" href="https://storymaps.arcgis.com/stories/4c11ad9244134ae788a44ed01ac57d04">Link to Full Report</a>
                        <div className="contents-line">
                            <div className="line-10"></div>
                        </div>
                        <div className="project-detail-description">
                            <p className="project-detail-description">
                            Acemoglu and Robinson's book, "Why States Fail," challenges the notion that geography is the primary determinant of a country's success or failure. Instead, the authors argue that politics and institutions play a crucial role in shaping a country's economic and political trajectory. The case study of North and South Korea, which share a common geographic and cultural heritage but have experienced vastly different outcomes, is used to illustrate this argument.
                            </p>
                            <p className="project-detail-description">
                            As an urban spatial analyst, the case study of North and South Korea is particularly intriguing. However, measuring the urban growth of these two countries is challenging due to the information blackout in North Korea. To overcome this, remote sensing data is being used to explore the urban spatial dynamics behind the divergent outcomes of North and South Korea. Specifically, factors such as land use patterns, infrastructure investments, and public policy are being examined to shed light on the institutional and political factors that drive or hinder economic growth in urban areas.
                            </p>
                            <p className="project-detail-description">
                            This research project aims to contribute to a deeper understanding of the drivers of national success and failure. By examining the growth and development of urban organizations in North and South Korea, insights can be uncovered that will inform policy decisions aimed at fostering economic growth in urban areas. Ultimately, the research could help policymakers create more effective strategies for promoting economic development and reducing inequality within and between countries.
                            </p>
                        </div>
                    </div>
                    <div className="project-info-credit">
                        UPENN MUSA
                        <br></br>
                        Minwook Kang
                        <br></br>
                        <br></br>

                        LECTURER
                        <br></br>
                        Vicky Tam
                    </div>
                </div>
                <div>
                {images.map((image, index) => (
                    <img className="project-image project-image-wide" src={image} alt={`Image ${index}`} onClick={() => handleImageClick(index)}  />
                ))}
                {showImageViewer && (
                    <ImageViewerWide
    images={images}
    selectedIndex={selectedImageIndex}
    onClose={handleCloseImageViewer}
    imageWidth="90%"
  />
                )}
                </div>


                
            </section>
        </div>
    );
}

export default RemoteSensingKR;
