import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

const useStyles = makeStyles((theme) => ({
    container: {
      position: "fixed",
      top: 0,
      left: 0,
      width: "100vw",
      height: "100vh",
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      zIndex: 1000,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    closeButton: {
      position: "absolute",
      top: theme.spacing(2),
      right: theme.spacing(2),
      color: theme.palette.common.white,
    },
    navigation: {
      position: "fixed",
      top: "50%",
      transform: "translateY(-50%)",
      zIndex: 1001,
      display: "flex",
      justifyContent: "space-between",
      width: "100vw",
    },
    leftButton: {
      position: "absolute",
      left: "100px",
      top: "50%",
      transform: "translateY(-50%)",
      color: "white"
    },
    rightButton: {
      position: "absolute",
      right: "100px",
      top: "50%",
      transform: "translateY(-50%)",
      color: "white"
    },
    image: {
      maxWidth: "100%",
      maxHeight: "100%",
    },
  }));
  

function ImageViewer(props) {
  const classes = useStyles();
  const [currentIndex, setCurrentIndex] = useState(props.selectedIndex);

  const handleNext = () => {
    setCurrentIndex((currentIndex + 1) % props.images.length);
  };

  const handlePrev = () => {
    setCurrentIndex((currentIndex - 1 + props.images.length) % props.images.length);
  };

  return (
    <div className={classes.container}>
        <IconButton className={classes.leftButton} onClick={handlePrev} style={{ color: "white" }}>
            <ArrowBackIcon />
        </IconButton>
        <img src={props.images[currentIndex]} alt="Selected" className={classes.image} style={{ padding: "50px" }} />
        <IconButton className={classes.rightButton} onClick={handleNext} style={{ color: "white" }}>
            <ArrowForwardIcon />
        </IconButton>
      <IconButton className={classes.closeButton} onClick={props.onClose}>
        <CloseIcon />
      </IconButton>
    </div>
  );
}

export default ImageViewer;
