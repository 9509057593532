import React, { useState } from "react";
import ImageViewer from "../components/ImageViewer";
import { useEffect } from "react";


function HosingSubsidy() {
    const [showImageViewer, setShowImageViewer] = useState(false);
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);

    // useEffect(() => {
    //     document.body.style.backgroundColor = "#061c40";  //set the background color
    //     return () => {
    //       document.body.style.backgroundColor = null;
    //     };
    //   }, []);
  
    const images = [];
    for (let i = 1; i <= 10; i++) {
      images.push(`/img/04/${i}.png`);
    }
      
    const handleImageClick = (index) => {
      setSelectedImageIndex(index);
      setShowImageViewer(true);
    };
  
    const handleCloseImageViewer = () => {
      setShowImageViewer(false);
    };
    
    return (
        <div className="project-container">
            <section className="project">
                <div className="project-header">
                    <div className="project-header-title">
                        <div className="contents-line">
                            <div className="line-10"></div>
                        </div>
                        <div className="project-main-title">
                            Targeting Housing Subsidy
                        </div>
                    </div>
                    <div className="project-header-year">
                        2022
                        <br></br>
                        <div className="proejct-category">
                            Data Analysis : R
                        </div>
                    </div>
                    
                </div>
                <div className="project-info">
                    <div className="project-description">
                        <a className="project-link" href="/html/Targeting_A_Housing_Subsidy.html">Link to markdown</a>
                        <div className="contents-line">
                            <div className="line-10"></div>
                        </div>
                        <div className="project-detail-description">
                            <p className="project-detail-description">
                            This analysis used a dataset of 4,119 samples of individuals eligible for housing subsidies, of which 89.05% did not receive subsidies and 10.95% received subsidies. The objective of the analysis was to strategically allocate housing subsidy resources by identifying groups that were willing to receive and had a relatively high probability of receiving subsidies, rather than randomly distributing resources. In addition, a cost/benefit analysis was conducted to draw the best conclusion. Two logistic regression models were developed: the first model used all available features (called the ‘kitchen sink model’) and the second model used feature engineered data. The McFadden R-squared was used to compare the models and the analysis found that model 1 had a higher R-squared value than model 2, indicating that it was the better of the two models. Sensitivity, specificity, and ROC analysis were also conducted for each model, with the engineered model performing better than the kitchen sink model in terms of sensitivity and generalizability.
                            </p>
                            <p className="project-detail-description">
                            The purpose of this analysis was not to allocate resources randomly, but rather to allocate resources strategically for maximum policy effect. The analysis showed that the sensitivity of the logistic regression models was almost 90%, which means that the models accurately identified who would receive the subsidy. By estimating the costs and benefits of each case, the analysis was able to derive thresholds that could be used to identify the most profitable groups in practice. This can be a useful tool for making decisions about resource allocation based on cost and profit.
                            </p>
                            <p className="project-detail-description">
                            It is worth noting that this analysis only considered the cost and benefit of policies and did not take into account other factors that may be important in policy-making. Nonetheless, the analysis demonstrates the value of using logistic regression models to identify groups that are most likely to receive housing subsidies and the importance of conducting a cost/benefit analysis to ensure that resources are allocated efficiently. By using this approach, policy-makers can make more informed decisions about how to allocate resources to maximize policy effectiveness.
                            </p>
                        </div>
                    </div>
                    <div className="project-info-credit">
                        UPENN MUSA
                        <br></br>
                        Minwook Kang
                        <br></br>
                        <br></br>
                        PROFESSOR
                        <br></br>
                        Elizabeth Delmelle<br></br>
                        Michael Fichman<br></br>
                        Matt Harris
                    </div>
                </div>
                <div>
                {images.map((image, index) => (
                    <img className="project-image" src={image} alt={`Image ${index}`} onClick={() => handleImageClick(index)}  />
                ))}
                {showImageViewer && (
                    <ImageViewer images={images} selectedIndex={selectedImageIndex} onClose={handleCloseImageViewer} />
                )}
                </div>


                
            </section>
        </div>
    );
}

export default HosingSubsidy;
