export const design = {
  type: "FeatureCollection",
  features: [
    {
      type: "Feature",
      properties: {
        id: 1,
        name: "Muju Public Platform",
        year: 2020,
        location: "Muju-gun, South Korea",
        route: "Muju-public-platform"
      },
      geometry: {
        type: "Point",
        coordinates: [127.65987670941828, 36.007919686989524],
      },
    },
    {
      type: "Feature",
      properties: {
        id: 2,
        name: "Jinju Public Library",
        year: 2021,
        location: "Jinju-si, South Korea",
        route: "Jinju-public-library"
      },
      geometry: {
        type: "Point",
        coordinates: [128.11128646715105,  35.20634841477309],
      },
    },
    {
      type: "Feature",
      properties: {
        id: 3,
        name: "Munsan Public Library",
        year: 2021,
        location: "Paju-si, South Korea",
        route: "Munsan-public-library"
      },
      geometry: {
        type: "Point",
        coordinates: [126.78346417405531, 37.8617075679967],
      },
    },
    {
      type: "Feature",
      properties: {
        id: 4,
        name: "Shizuoka Airport",
        year: 2015,
        location: "Shizuoka, Japan",
        route: "Shizuoka-airport"
      },
      geometry: {
        type: "Point",
        coordinates: [138.18753689366537, 34.79518554259407],
      },
    },
  ],
};
