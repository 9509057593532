import React, { useState, useRef } from "react";
import { Marker, Popup, useMap } from "react-leaflet";
import { aboutIcon } from "../icons/aboutIcon";
import { aboutIconHover } from "../icons/aboutIconHover";
import "./tooltip.css"; 

export const AboutMap = ({data}) => {
    const leafletMap = useMap();
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [hoveredIndex, setHoveredIndex] = useState(-1);
    const popupRef = useRef();

    return data.features.map((feature, index) =>{
        const {coordinates} = feature.geometry;
        const {id, content, year, location} = feature.properties;
        const icon = hoveredIndex === index ? aboutIconHover : aboutIcon;

        return(
            <Marker 
                key={String(coordinates)} 
                position={[coordinates[1],coordinates[0]]} 
                icon={icon}
                eventHandlers={{
                    click: (e) => {
                        leafletMap.flyTo(e.latlng,10);
                        popupRef.current.openPopup();
                    },
                    mouseover: () => setHoveredIndex(index),
                    mouseout: () => setHoveredIndex(-1),
                }}
            >
                <Popup 
                    className="custom-popup"
                    ref={popupRef}
                    closeButton={false}
                    onOpen={() => setTooltipOpen(true)}
                    onClose={() => setTooltipOpen(false)}
                >
                    <div className="popup-title-container">
                        <a className="popup-titlea-about"><b>{year}</b></a><br/>
                    </div>
                    <div>
                        - {content}<br/>
                        - {location} <br/>
                    </div>
                </Popup>
            </Marker>
        )
    })    
}

export default AboutMap;
