import React, { useState } from "react";
import ImageViewer from "../components/ImageViewer";
import { useEffect } from "react";


function BikesharePrediction() {
    const [showImageViewer, setShowImageViewer] = useState(false);
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);

    // useEffect(() => {
    //     document.body.style.backgroundColor = "#061c40";  //set the background color
    //     return () => {
    //       document.body.style.backgroundColor = null;
    //     };
    //   }, []);
  
    const images = [];
    for (let i = 1; i <= 23; i++) {
      images.push(`/img/03/${i}.png`);
    }
      
    const handleImageClick = (index) => {
      setSelectedImageIndex(index);
      setShowImageViewer(true);
    };
  
    const handleCloseImageViewer = () => {
      setShowImageViewer(false);
    };
    
    return (
        <div className="project-container">
            <section className="project">
                <div className="project-header">
                    <div className="project-header-title">
                        <div className="contents-line">
                            <div className="line-10"></div>
                        </div>
                        <div className="project-main-title">
                            Citibike Ridership Prediction in NY
                        </div>
                    </div>
                    <div className="project-header-year">
                        2022
                        <br></br>
                        <div className="proejct-category">
                            Data Analysis : R
                        </div>
                    </div>
                    
                </div>
                <div className="project-info">
                    <div className="project-description">
                        <a className="project-link" href="/html/Bike_Share_Prediction_Min.html">Link to markdown</a>
                        <div className="contents-line">
                            <div className="line-10"></div>
                        </div>
                        <div className="project-detail-description">
                            <p className="project-detail-description">
                            Bike-sharing infrastructure is gaining popularity worldwide due to its affordability, convenience, and environmental benefits. However, bike-sharing systems face the challenge of managing imbalances in the availability of bicycles at different locations due to one-way trips, leading to shortages or empty stands at certain stops. To address this issue, effective strategies for bicycle redistribution are necessary, which require accurate prediction of demand at each stop. In this context, a study was conducted in New York City to develop a framework to predict hourly bicycle demand at each stop during a five-week period from September to December.
                            </p>
                            <p>
                            The study found that the proposed framework was able to predict hourly bicycle demand at each stop with reasonable accuracy, and the use of 15-minute increments improved the precision of the predictions, particularly during peak hours. The study evaluated the performance of the framework using metrics such as mean absolute error (MAE) and root mean squared error (RMSE). The results showed that the model's MAE for bike share predictions of each station was below 1, which means that the model was very accurate in predicting demand at each stop.
                            </p>
                            <p>
                            The study's findings can be used to improve the efficiency and sustainability of bike-sharing systems by providing valuable insights into the factors that affect bicycle demand and enabling more effective redistribution of bicycles. Bike-sharing systems can use the framework to optimize their operations, such as increasing the number of bicycles available during peak hours and ensuring they are distributed appropriately. Additionally, the proposed framework can be applied to other cities and regions to predict bicycle demand and inform the development of policies and strategies to improve the management of bike-sharing systems. By doing so, bike-sharing systems can become more sustainable, user-friendly, and beneficial for both users and the environment.
                            </p>
                        </div>
                    </div>
                    <div className="project-info-credit">
                        UPENN MUSA
                        <br></br>
                        Minwook Kang
                        <br></br>
                        <br></br>
                        PROFESSOR
                        <br></br>
                        Elizabeth Delmelle<br></br>
                        Michael Fichman<br></br>
                        Matt Harris
                    </div>
                </div>
                <div>
                {images.map((image, index) => (
                    <img className="project-image" src={image} alt={`Image ${index}`} onClick={() => handleImageClick(index)}  />
                ))}
                {showImageViewer && (
                    <ImageViewer images={images} selectedIndex={selectedImageIndex} onClose={handleCloseImageViewer} />
                )}
                </div>


                
            </section>
        </div>
    );
}

export default BikesharePrediction;
