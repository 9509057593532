import React, { useState } from "react";
import ImageViewerWide from "../components/ImageViewerWide";
import { useEffect } from "react";


function AnotherSeoul() {
    const [showImageViewer, setShowImageViewer] = useState(false);
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);

    // useEffect(() => {
    //     document.body.style.backgroundColor = "black";  //set the background color
    //     return () => {
    //       document.body.style.backgroundColor = null;
    //     };
    //   }, []);
  
    const images = [];
    for (let i = 1; i <= 3; i++) {
      images.push(`/img/Research/AnotherSeoul/${i}.jpg`);
    }
      
    const handleImageClick = (index) => {
      setSelectedImageIndex(index);
      setShowImageViewer(true);
    };
  
    const handleCloseImageViewer = () => {
      setShowImageViewer(false);
    };
    
    return (
        <div className="project-container">
            <section className="project">
                <div className="project-header">
                    <div className="project-header-title">
                        <div className="contents-line">
                            <div className="line-10"></div>
                        </div>
                        <div className="project-main-title">
                            An-other Seoul : Rivers and Mountains
                        </div>
                    </div>
                    <div className="project-header-year">
                        2019-2021
                        <br></br>
                        
                        <div className="proejct-category">
                            Research <br></br>
                        </div>
                    </div>
                </div>
                <div className="project-info">
                    <div className="project-description">
                        <a className="project-link" href="https://sca.seoul.go.kr/seoulhour/site/urbanArch/exhibition/exhibitPast/624">Link to Exhibition</a><br></br>
                        <a className="project-link" href="https://youtu.be/DgmlKs7lm4M">Link to Video</a>
                        <div className="contents-line">
                            <div className="line-10"></div>
                        </div>
                        <div>
                            <p className="project-detail-description">
                                As a city with limited developable land and strong development pressure, Seoul stands out for its vast area of rivers and mountains. These natural features offer a unique opportunity as urban voids, providing space for public activities and events without being filled up.
                            </p>
                            <p className="project-detail-description">
                                To revitalize these areas, we focused on the surrounding infrastructure, including retention ponds, drainage ditches, schools, rainwater pumping stations, and vehicle depots. By redefining infrastructure and exploring new possibilities, we developed a range of architectural types that utilize vacant land to create a vibrant public realm.
                            </p>
                            <p className="project-detail-description">
                                My role in this project involved extensive research into the different types of vacant land, analyzing them quantitatively to identify opportunities for architectural interventions. Through this research, we uncovered the potential of these natural features to maximize the city's potential and create new spaces for public engagement.
                            </p>
                            <p className="project-detail-description">
                                In addition to my research contributions, I played a critical role in the delivery and dissemination of our findings. The research was compiled into a comprehensive report spanning approximately 1,000 pages and delivered to the city of Seoul.
                            </p>
                            <p className="project-detail-description">
                                Furthermore, the report was adapted into a permanent exhibition at the Seoul Urban Architecture Exhibition Center, which ran for approximately a year. I took a leadership role in editing and publishing the report, ensuring its accessibility and clarity to a wide audience. Additionally, I contributed to the planning and design of the exhibition center, ensuring that it effectively conveyed our research findings to visitors.
                            </p>
                        </div>
                    </div>
                    <div className="project-info-credit">
                        URBAN OPERATIONS <br></br>
                        <br></br>
                        Lee Janghwan<br></br>
                        Kang Minwook<br></br>
                        Kim Juhyen<br></br>
                        No Huncheol<br></br>
                    </div>
                </div>
                <div>
                {images.map((image, index) => (
                    <img className="project-image project-image-wide" src={image} alt={`Image ${index}`} onClick={() => handleImageClick(index)}  />
                ))}
                {showImageViewer && (
                    <ImageViewerWide
    images={images}
    selectedIndex={selectedImageIndex}
    onClose={handleCloseImageViewer}
    imageWidth="65%"
  />
                )}
                </div>


                
            </section>
        </div>
    );
}

export default AnotherSeoul;
