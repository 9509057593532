import L from "leaflet";
import Png from "./../images/Icon-11.png"

// Making custom Icon
const LeafIcon = L.Icon.extend({
    options:{
        iconSize: [25, 25],
        iconAnchor: [13, 10],
        tooltipAnchor: [0, 0]
    }
})

export const designIconHover = new LeafIcon({iconUrl: Png});