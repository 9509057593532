import React, { useState } from "react";
import ImageViewerWide from "../components/ImageViewerWide";
import { useEffect } from "react";



function SchoolExplorer() {
    const [showImageViewer, setShowImageViewer] = useState(false);
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);

    // useEffect(() => {
    //     document.body.style.backgroundColor = "#061c40";
    //     return () => {
    //       document.body.style.backgroundColor = null;
    //     };
    //   }, []);
  
    const images = [];
    for (let i = 1; i <= 1; i++) {
      images.push(`/img/08/${i}.png`);
    }
      
    const handleImageClick = (index) => {
      setSelectedImageIndex(index);
      setShowImageViewer(true);
    };
  
    const handleCloseImageViewer = () => {
      setShowImageViewer(false);
    };
    
    return (
        <div className="project-container">
            <section className="project">
                <div className="project-header">
                    <div className="project-header-title">
                        <div className="contents-line">
                            <div className="line-10"></div>
                        </div>
                        <div className="project-main-title">
                            School Explorer in Philadelphia
                        </div>
                    </div>
                    <div className="project-header-year">
                        2022
                        <br></br>
                        <div className="proejct-category">
                            Data Visulization : Javascript, Leaflet
                        </div>
                    </div>
                </div>
                <div className="project-info">
                    <div className="project-description">
                        <a className="project-link" href="https://kmu973.github.io/schoolExplorer/">Link to App</a>
                        <div className="contents-line">
                            <div className="line-10"></div>
                        </div>
                        <div className="project-detail-description">
                            <p className="project-detail-description">
                            As part of the Javascript class in the Master of Urban Spatial Analytics course, this project entailed implementing Geojson data extraction and mapping to a web map using Leaflet and Mapbox. The project also included developing the ability to filter and search for the properties of the Geojson feature of interest in Javascript.
                            </p>
                            <p className="project-detail-description">
                            The project focuses on schools located in Pennsylvania, and the data can be sorted or searched by grade. The filtered schools are ultimately displayed as a list, making it easier for users to access the information they need. This implementation showcases how Javascript can be leveraged to enhance the functionality of mapping applications.
                            </p>
                        </div>
                    </div>
                    <div className="project-info-credit">
                        UPENN MUSA
                        <br></br>
                        Minwook Kang
                        <br></br>
                        <br></br>
                        LECTURER<br></br>
                        Mjumbe Poe
                    </div>
                </div>
                <div>
                {images.map((image, index) => (
                    <img className="project-image-wide" src={image} alt={`Image ${index}`} onClick={() => handleImageClick(index)}  />
                ))}
                {showImageViewer && (
                    <ImageViewerWide
    images={images}
    selectedIndex={selectedImageIndex}
    onClose={handleCloseImageViewer}
    imageWidth="90%"
  />
                )}
                </div>


                
            </section>
        </div>
    );
}

export default SchoolExplorer;
