import React from "react";
import ImageGrid from './DesignGrid';
import MapDesign from "./MapDesign";


function Design() {
    return (
        <>
            <MapDesign />
            <div className="container-mainpage">
                <div className="contents-line"> 
                        <a href ="#Data-Analysis" className="contents-line-text" id="Data-Analysis"> 🞦 Design </a>
                </div>
                <section className="data-image" >   
                    <ImageGrid />
                </section>
            </div>
        </>
    )
}

export default Design;
