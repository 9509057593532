import React from "react";
import MapAbout from "./MapAbout"


function About() {
    return (
        <>

        <MapAbout></MapAbout>
        <div className="about-container">
            <div className="profile-container">
                    <div className="about-header">
                        <div className="about-header-title">
                            <div className="about-main-image"> 
                            <img className="profile-image" src="/img/Profile/profile.png"></img>
                            </div>
                        </div>
                        <div className="about-header-year">
                            <br></br>
                            <div className="proejct-category">
                                <a className= "cv-link" href ="https://drive.google.com/file/d/1dR0Z0GOrLZ0cxhSrXHpBzpukYgjB-QdJ/view?usp=sharing"> CV </a>
                            </div>
                        </div>
                    </div>
            </div>
            <div className="cv-text-containter">
                    <div className="about-text">
                    <p>Hey there! I'm a spatial analyst and architect who's passionate about making our world a better, more transparent place. I call myself an urban analyst because I love looking at the big picture of how our cities work and finding ways to make them work even better. I use cool tools like data visualization and mapping to help people understand what's going on in their communities and how we can all work together to create positive change.</p>
                    </div> 
                    <div className="about-text">
                    <p>I'm also what you might call a philomath – basically, I'm a big nerd who loves learning new things. I'm always reading up on the latest research and chatting with experts in fields like urban planning, public health, and economics. I figure the more I know, the better equipped I am to come up with innovative solutions to the complex problems facing our cities</p>
                    </div>  
                    <div className="about-text">
                    <p>Finally, as a civic technician, I'm all about using my skills to make a real difference in the world. I'm super passionate about community development and working with local organizations to promote social and environmental justice. I think if we all work together, using technology and data to guide us, we can create cities that are more inclusive, sustainable, and just plain awesome. So, let's get to it!</p>
                    </div>  
                    {/* <div className="about-text">
                    <p>During my downtime, I love to pursue my passions and hobbies. Music is a huge part of my life, and I enjoy collecting tracks and creating sets as an amateur DJ. It's a fun way to socialize and unwind after a long day at work. I'm also an avid hiker and enjoy exploring the great outdoors. And when I'm feeling particularly creative in the kitchen, I love experimenting with new recipes, especially when it comes to authentic Korean cuisine. If you're ever in the mood for some seriously delicious food, I'd be happy to share my recipes with you!</p>
                    </div>   */}
                    
            </div>  
        </div>
        </>
    )
}

export default About;
