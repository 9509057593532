import React, {useEffect, useRef} from "react";
import {MapContainer, TileLayer} from "react-leaflet";

import {dataAnalysis} from "../data/dataAnalysis";
import {About} from "../data/About";
import {research} from "../data/research";
import {design} from "../data/design";



import {DataAnalysisMap} from "../map_layers/dataAnalysisMap";
import {AboutMap} from "../map_layers/AboutMap";
import {ResearchMap} from "../map_layers/researchMap";
import {DesginMap} from "../map_layers/DesignMap";


import HomeButton from "./HomeButton";


import "./Map.css"; // Import the CSS file containing the attribution text color rule

const position = [35 , 10];
const zoom = 3;

function MapData() {

    const scrollWheelZoom = false;

    useEffect(() => {
        const mapContainer = document.getElementById('map');
        mapContainer.addEventListener('mousewheel', handleMouseWheel);
        return () => mapContainer.removeEventListener('mousewheel', handleMouseWheel);
    }, []);

    const handleMouseWheel = (event) => {
        if (event.shiftKey) {
            const delta = event.deltaY;
            const zoom = mapRef.current.getZoom();
            if (delta > 0) {
                mapRef.current.setZoom(zoom - 1);
            } else if (delta < 0) {
                mapRef.current.setZoom(zoom + 1);
            }
        }
    };

    const mapRef = useRef();

    return (
        <section className="map-page">   
            <div className="map"> 
                <div id="map">
                    <MapContainer center={[35, 10]} zoom={3}  scrollWheelZoom={scrollWheelZoom} ref={mapRef}>
                        <TileLayer className="custom-attribution" 
                        attribution="<a href='https://www.mapbox.com/about/maps/'>Mapbox </a> <a href='http://www.openstreetmap.org/copyright'> © OpenStreetMap</a> <a><p>Press Shift to zoom with the wheel</p></a>"
                        url="https://api.mapbox.com/styles/v1/kmu973/cldmlrp3u00bk01o6g2vezhle/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1Ijoia211OTczIiwiYSI6ImNsZG1seTNkdzBhcjgzb2tiM2IyOWxsajYifQ.ElgPUZs6ITdngOqOQnvt1g"
                        />
                        {/* <MarkerLayer data={cities}/> */}
                        <DataAnalysisMap data={dataAnalysis}/>
                        {/* <AboutMap data={About}/>
                        <ResearchMap data={research}/>
                        <DesginMap data={design}/> */}
                        <HomeButton position={position} zoom={zoom} />
                    </MapContainer>
                </div>
            </div>
        </section>
    )
}

export default MapData;
