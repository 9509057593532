export const dataAnalysis = {
  type: "FeatureCollection",
  features: [
    {
      type: "Feature",
      properties: {
        id: 1,
        name: "Urbanization of Korean Peninsular",
        year: 2022,
        location: "South Korea",
        route: "Urbanizaion-Korea-Peninsular",
        img: '/img/07/1-2.gif'
      },
      geometry: {
        type: "Point",
        coordinates: [127.323464939363, 38.42569790464331],
      },
    },
    {
      type: "Feature",
      properties: {
        id: 2,
        name: "TOD Study in NY",
        year: 2022,
        location: "New York",
        route: "Tod-study",
        img: '/img/01/2.png'
      },
      geometry: {
        type: "Point",
        coordinates: [-73.91503836377008, 40.76448211606499],
      },
    },
    {
      type: "Feature",
      properties: {
        id: 3,
        name: "Fire Risk Prediction in CA",
        year: 2022,
        location: "California",
        route: "Fire-risk-prediction",
        img: '/img/02/B.gif',
      },
      geometry: {
        type: "Point",
        coordinates: [-119.56757094949568, 36.882116209443766],
      },
    },
    {
      type: "Feature",
      properties: {
        id: 4,
        name: "Citi Bike Ridership Prediction in NY",
        year: 2022,
        location: "New York",
        route: "Bike-prediction",
        img: '/img/03/20.png',
      },
      geometry: {
        type: "Point",
        coordinates: [-73.98017994536434, 40.76022256387778],
      },
    },
    {
      type: "Feature",
      properties: {
        id: 5,
        name: "Targeting Housing Subsidy",
        year: 2022,
        location: "Philadelphia",
        route: "Housing-subsidy",
        img: '/img/04/7.png',
      },
      geometry: {
        type: "Point",
        coordinates: [-75.16256994796956, 39.95314713245014],
      },
    },
    {
      type: "Feature",
      properties: {
        id: 6,
        name: "Automobile Theft Risk Prediction",
        year: 2022,
        location: "Chicago",
        route: "Risk-prediction",
        img: '/img/05/12.png',
      },
      geometry: {
        type: "Point",
        coordinates: [-87.6899053263444, 41.85884758642673],
      },
    },
    {
      type: "Feature",
      properties: {
        id: 7,
        name: "Housing Price Prediction",
        year: 2022,
        location: "Charlotte",
        route: "Home-price-prediction",
        img: '/img/06/16.png',
      },
      geometry: {
        type: "Point",
        coordinates: [-80.84160365846105, 35.225763604652656],
      },
    },
    {
      type: "Feature",
      properties: {
        id: 8,
        name: "School Explorer",
        year: 2022,
        location: "Philadelphia",
        route: "school-explorer",
        img: '/img/08/1.png',
      },
      geometry: {
        type: "Point",
        coordinates: [-75.17256994796956, 39.96314713245014],
      },
    },
    {
      type: "Feature",
      properties: {
        id: 9,
        name: "Indego Station Planner",
        year: 2022,
        location: "Philadelphia",
        route: "indego-expansion",
        img: '/img/09_IndegoExpansion/grid.png',
      },
      geometry: {
        type: "Point",
        coordinates: [-75.19294351478706, 39.95425918329972],
      },
    },
  ],
};