import React from "react";
import ImageGrid from './ResearchGrid';
import MapResearch from "./MapResearch"


function Research() {
    return (
        <>
            <MapResearch />

            <div className="container-mainpage">
                <div className="contents-line"> 
                        <a href ="#Data-Analysis" className="contents-line-text" id="Data-Analysis"> 🞑 Research </a>
                </div>
                <section className="data-image" >   
                    <ImageGrid />
                </section>
            </div>
        </>
    )
}

export default Research;
