import React from "react";
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import About from './components/About';
import Main from './components/Main';
import DataAnalysis from './components/DataAnalysis';
import Design from './components/Design';
import Research from './components/Research';
import HomePricePrediction from './projects/HomePricePrediction'
import TodStudy from './projects/TodStudy'
import FireRiskPrediction from './projects/FireRiskPrediction'
import BikesharePrediction from './projects/BikesharePrediction'
import HosingSubsidy from './projects/HosingSubsidy'
import RiskPrediction from './projects/RiskPrediction'
import RemoteSensingKR from './projects/RemoteSensingKR'
import MujuPublicPlatform from './projects/MujuPublicPlatform'
import MunsanLibrary from './projects/MunsanLibrary'
import JinjuLibrary from './projects/JinjuLibrary'
import ShizuokaAirport from './projects/ShizuokaAirport'
import AnotherSeoul from './projects/AnotherSeoul'
import PublicAssets from './projects/PublicAssets'
import ChildrensPark from './projects/ChildrensPark'
import AnotherSeoul2 from './projects/AnotherSeoul2'
import SchoolExplorer from './projects/SchoolExplorer'
import IndegoExpansion  from './projects/IndegoExpansion'
import VacantLotDetection  from './projects/VacantLotDetection'




import "leaflet/dist/leaflet.css";
import "antd/dist/reset.css";


function App(){
    return(
            <Router>
                <Header />
                <Routes>
                    <Route path="/" element={<Main />} />
                    <Route path="/data_analysis" element={<DataAnalysis />} />
                    <Route path="/research" element={<Research />} />
                    <Route path="/design" element={<Design />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/Home-price-prediction" element={<HomePricePrediction />} />
                    <Route path="/Tod-study" element={<TodStudy />} />
                    <Route path="/Fire-risk-prediction" element={<FireRiskPrediction />} />
                    <Route path="/Bike-prediction" element={<BikesharePrediction />} />
                    <Route path="/Housing-subsidy" element={<HosingSubsidy />} />
                    <Route path="/Risk-prediction" element={<RiskPrediction />} />
                    <Route path="/Urbanizaion-Korea-Peninsular" element={<RemoteSensingKR />} />
                    <Route path="/Muju-public-platform" element={<MujuPublicPlatform />} />
                    <Route path="/Munsan-public-library" element={<MunsanLibrary />} />
                    <Route path="/Jinju-public-library" element={<JinjuLibrary />} />
                    <Route path="/Shizuoka-airport" element={<ShizuokaAirport />} />
                    <Route path="/An-other-Seoul" element={<AnotherSeoul />} />
                    <Route path="/Public-assets" element={<PublicAssets />} />
                    <Route path="/childrens-park" element={<ChildrensPark />} />
                    <Route path="/An-other-Seoul-2" element={<AnotherSeoul2 />} />
                    <Route path="/school-explorer" element={<SchoolExplorer />} />
                    <Route path="/indego-expansion" element={<IndegoExpansion />} />
                    <Route path="/philly-vacant-lot" element={<VacantLotDetection />} />
                </Routes>
                <Footer />
            </Router>
    );
};

export default App;