import L from "leaflet";
import homePng from "./../images/Icon-17.png"

// Making custom Icon
const LeafIcon = L.Icon.extend({
    options:{
        iconSize: [5, 5],
        iconAnchor: [0, 0],
        tooltipAnchor: [0, 0]
    }
})

export const homeIcon = new LeafIcon({iconUrl: homePng});