import React, { useState, useEffect } from 'react';

const DataAnalysisGrid = () => {
  const [gridItems, setGridItems] = useState([]);

  useEffect(() => {
    const items = [
      {
        img: '/img/09_IndegoExpansion/grid.png',
        title: 'Bike Share Expansion Planner of Philadelphia',
        subject: 'Machine Learning, Data Analysis, Web App',
        route: 'indego-expansion'
      },
      {
        img: '/img/10_VacantLots/grid.png',
        title: 'Vacant Lot Detection of Philadelphia',
        subject: 'Remote Sensing, Deep Learning, Data Analysis',
        route: 'philly-vacant-lot'
      },
      {
        img: '/img/02/B.gif',
        title: 'Fire Risk Prediction in CA',
        subject: 'Machine Learning, Data Analysis',
        route: 'Fire-risk-prediction'
      },
      {
        img: '/img/07/1-2.gif',
        title: 'Urbanization of Korean Peninsular',
        subject: 'Remote Sensing, Data Analysis, Story Map',
        route: 'Urbanizaion-Korea-Peninsular'
      },
      {
        img: '/img/03/20.png',
        title: 'Citi Bike Rideshare Prediction in NY',
        subject: 'Machine Learning, Data Analysis',
        route: 'Bike-prediction'     
      },
      {
        img: '/img/08/1.png',
        title: 'School Explorer in Philadelphia',
        subject: 'Web App, Data Analysis',
        route: 'school-explorer'
      },
      {
        img: '/img/04/7.png',
        title: 'Targeting Housing Subsidy',
        subject: 'Machine Learning, Data Analysis',
        route: 'Housing-subsidy'   
      },
      {
        img: '/img/05/10.png',
        title: 'Automobile Theft Risk in Chicago',
        subject: 'Machine Learning, Data Analysis',
        route: 'Risk-prediction'
      },
      {
        img: '/img/06/16.png',
        title: 'Housing Price Prediction in Charlotte',
        subject: 'Machine Learning, Data Analysis',
        route: 'Home-price-prediction'
      },
      {
        img: '/img/01/2.png',
        title: 'TOD Study in NY',
        subject: 'Data Analysis',
        route: 'Tod-study'
      },

      // Add more items as needed
    ];

    setGridItems(items);
  }, []);

  return (
    <div className="image-grid">
      {gridItems.map((item, index) => (
          <a href = {`/${item.route.replace(/ /g, '-')}`} className="grid-item" key={index} >
            <div className="image-border">
               <img className="image-grid-images" src={item.img} alt={`image ${index + 1}`}/>
            </div>
            <div className="image-title">{item.title}</div>
            <div className="image-subject">{item.subject}</div>
          </a>
      ))}
    </div>
  );
};

export default DataAnalysisGrid;
