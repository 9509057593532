import React, { useState } from "react";
import ImageViewerWide from "../components/ImageViewerWide";
import { useEffect } from "react";


function ShizuokaAirport() {
    const [showImageViewer, setShowImageViewer] = useState(false);
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);

    // useEffect(() => {
    //     document.body.style.backgroundColor = "#385F67";  //set the background color
    //     return () => {
    //       document.body.style.backgroundColor = null;
    //     };
    //   }, []);
  
    const images = [];
    for (let i = 1; i <= 1; i++) {
      images.push(`/img/Design/04_Shizuoka/${i}.jpg`);
    }
      
    const handleImageClick = (index) => {
      setSelectedImageIndex(index);
      setShowImageViewer(true);
    };
  
    const handleCloseImageViewer = () => {
      setShowImageViewer(false);
    };
    
    return (
        <div className="project-container">
            <section className="project">
                <div className="project-header">
                    <div className="project-header-title">
                        <div className="contents-line">
                            <div className="line-10"></div>
                        </div>
                        <div className="project-main-title">
                            Shizuoka Airport Renovation Porposal
                        </div>
                    </div>
                    <div className="project-header-year">
                        2015
                        <br></br>
                        <div className="proejct-category">
                            Architecture
                        </div>
                    </div>
                </div>
                <div className="project-info">
                    <div className="project-description">
                        <a className="project-link" href="http://www.shigerubanarchitects.com/works.html">Link to Related Site</a>
                        <div className="contents-line">
                            <div className="line-10"></div>
                        </div>
                        <div>
                            <p className="project-detail-description">
                            As an intern at Shigeru Ban's office in Tokyo, I had the opportunity to work on the Shizuoka Airport renovation project. It was an exciting experience to witness first-hand how world-renowned and innovative designs are planned, designed, and constructed using wood structures. My primary responsibility was to participate in the process of modeling the structural and mathematical clarity of the wooden structure using Grasshopper. I also utilized the model to communicate with the structural office and contribute to the project's successful completion.
                            </p>
                            <p className="project-detail-description">
                            The Shizuoka Airport renovation project involved the transformation of the existing airport terminal into a modern, environmentally sustainable structure. Shigeruban's innovative approach to design, particularly the use of wood structures, was integral to the project's success. Through the use of Grasshopper, I was able to participate in the process of modeling the structural and mathematical clarity of the wooden structure, contributing to the project's construction process. The experience provided me with valuable insights into the application of wood structures in large-scale architectural projects.
                            </p>
                            <p className="project-detail-description">
                            My time at Shigeru Ban's office was a valuable learning experience that allowed me to witness the planning, design, and construction of world-renowned and innovative designs. The Shizuoka Airport renovation project allowed me to participate in the modeling of the wooden structure using Grasshopper, providing me with valuable skills and knowledge that I can apply in future architectural projects. Overall, the experience allowed me to witness the use of wood structures in large-scale architectural projects and contribute to the successful completion of the Shizuoka Airport renovation project.
                            </p>
                        </div>
                    </div>
                    <div className="project-info-credit">
                        SHIGERU BAN ARCHITECTS<br></br>
                        Intern Architects
                    </div>
                </div>
                <div>
                {images.map((image, index) => (
                    <img className="project-image project-image-wide" src={image} alt={`Image ${index}`} onClick={() => handleImageClick(index)}  />
                ))}
                {showImageViewer && (
                    <ImageViewerWide
    images={images}
    selectedIndex={selectedImageIndex}
    onClose={handleCloseImageViewer}
    imageWidth="85%"
  />
                )}
                </div>


                
            </section>
        </div>
    );
}

export default ShizuokaAirport;
