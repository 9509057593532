import React, { useState, useRef } from "react";
import { Marker, Popup, useMap } from "react-leaflet";
import { designIcon } from "../icons/designIcon";
import { designIconHover } from "../icons/designIconHover";
import "./tooltip.css"; 

export const DesginMap = ({data}) => {
    const leafletMap = useMap();
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [hoveredIndex, setHoveredIndex] = useState(-1);
    const popupRef = useRef();

    return data.features.map((feature, index) =>{
        const {coordinates} = feature.geometry;
        const {name, year, location, route} = feature.properties;
        const icon = hoveredIndex === index ? designIconHover : designIcon;

        return(
            <Marker 
                key={String(coordinates)} 
                position={[coordinates[1],coordinates[0]]} 
                icon={icon}
                eventHandlers={{
                    click: (e) => {
                        leafletMap.flyTo(e.latlng, 10);
                        popupRef.current.openPopup();
                    },
                    mouseover: () => setHoveredIndex(index),
                    mouseout: () => setHoveredIndex(-1),
                }}
            >
                <Popup 
                    className="custom-popup"
                    ref={popupRef}
                    closeButton={false}
                    onOpen={() => setTooltipOpen(true)}
                    onClose={() => setTooltipOpen(false)}
                >
                    <div className="popup-title-container">
                        <a href={`/${route}`} className="popup-title"><b>{name}</b></a><br/>
                    </div>
                    <div>
                        Year: {year}<br/>
                        Location: {location} <br/>
                    </div>
                </Popup>
            </Marker>
        )
    })    
}

export default DesginMap;
