import React, { useState, useEffect } from 'react';

const ResearchGrid = () => {
  const [gridItems, setGridItems] = useState([]);

  useEffect(() => {
    const items = [
      {
        img: '/img/Research/anotherSeoul/1.jpg',
        title: 'An-other Seoul : River and Mountains',
        route: 'An-other-Seoul'
      },
      {
        img: '/img/Research/publicAssets/2.jpg',
        title: 'Analyze municipally-owned real estates',
        route: 'Public-assets'
      },
      {
        img: '/img/Research/childrenParks/1.jpg',
        title: 'Proposal For The Low-used Childrens Park',
        route: 'childrens-park'
      },
      {
        img: '/img/Research/anotherSeoul2/1.jpg',
        title: 'An-other Seoul : Potential territories',
        route: 'An-other-Seoul-2'     
      },

      // Add more items as needed
    ];

    setGridItems(items);
  }, []);

  return (
    <div className="image-grid">
      {gridItems.map((item, index) => (
          <a href = {`/${item.route.replace(/ /g, '-')}`} className="grid-item" key={index} >
            <div className="image-border">
               <img className="image-grid-images" src={item.img} alt={`image ${index + 1}`}/>
            </div>
            <div className="image-title">{item.title}</div>
          </a>
      ))}
    </div>
  );
};

export default ResearchGrid;
