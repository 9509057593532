import React, { useState } from "react";
import ImageViewer from "../components/ImageViewer";
import { useEffect } from "react";


function FireRiskPrediction() {
    const [showImageViewer, setShowImageViewer] = useState(false);
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);

    // useEffect(() => {
    //     document.body.style.backgroundColor = "#3b0200";  //set the background color
    //     return () => {
    //       document.body.style.backgroundColor = null;
    //     };
    //   }, []);
  
    const images = ["/img/02/B.gif"];
    for (let i = 1; i <= 19; i++) {
      images.push(`/img/02/${i}.png`);
    }
      
    const handleImageClick = (index) => {
      setSelectedImageIndex(index);
      setShowImageViewer(true);
    };
  
    const handleCloseImageViewer = () => {
      setShowImageViewer(false);
    };
    
    return (
        <div className="project-container">
            <section className="project">
                <div className="project-header">
                    <div className="project-header-title">
                        <div className="contents-line">
                            <div className="line-10"></div>
                        </div>
                        <div className="project-main-title">
                            Forecasting Wildfire Risk in California
                        </div>
                    </div>
                    <div className="project-header-year">
                        2022
                        <br></br>
                        <div className="proejct-category">
                            Data Analysis : R
                        </div>
                    </div>
                </div>
                <div className="project-info">
                    <div className="project-description">
                        <a className="project-link" href="/html/Minwook Kang & Shengao Yi.html">Link to markdown</a>
                        <div className="contents-line">
                            <div className="line-10"></div>
                        </div>
                        <div className="project-detail-description">
                            <p className="project-detail-description">
                            California is highly susceptible to wildfires during the dry, hot, and windy summer and fall months. These wildfires can have devastating impacts on property, air pollution, and loss of life, as well as long-term ecological damage. Developing a forecasting model for wildfires in California could be highly beneficial in predicting when and where fires are most likely to occur. By analyzing weather conditions, topography, vegetation density, and past fire history, the model can identify high-risk areas and the timing and severity of potential fires. This information can help emergency responders, including firefighters, prepare for and respond to fires more effectively, reducing the damage caused by wildfires. Furthermore, the information generated by the model can inform policy decisions aimed at reducing the risks associated with wildfires and help protect communities, wildlife, and the environment.
                            </p>
                            <p>
                            To support this effort, a Logistic Regression model has been developed to predict the probability of wildfire occurrence in California. The model includes two types based on time intervals: one based on a yearly interval and another based on monthly intervals. The yearly interval model's goodness of fit is high, with an AUC of 0.85, and a sensitivity of 0.89 after cross-validation on 100 k-folds. However, the specificity needs improvement. By conducting a weight scenario analysis, an optimized model has been developed with a sensitivity increase and decreased specificity. However, the year prediction map is not very helpful, as almost everywhere will occur wildfires without time information. Therefore, 12 models have been developed based on monthly intervals, and prediction maps have been created for each month. These results are convincing, and further factors related to wildfires, such as drought conditions and reservoir points, should be added to improve accuracy.
                            </p>
                            <p>
                            Overall, the forecasting model and web application can enhance the understanding of the factors that contribute to wildfires in California, provide valuable insights to emergency responders and policymakers, and help mitigate the risks associated with wildfires. Despite the need for improvement, the developed model can make significant progress in predicting wildfire risks and support the government department.
                            </p>

                        </div>
                    </div>
                    <div className="project-info-credit">
                        UPENN MUSA
                        <br></br>
                        Minwook Kang
                        <br></br>
                        Shengao Yi
                        <br></br>
                        <br></br>
                        PROFESSOR
                        <br></br>
                        Elizabeth Delmelle<br></br>
                        Michael Fichman<br></br>
                        Matt Harris
                    </div>
                </div>
                <div>
                {images.map((image, index) => (
                    <img className="project-image" src={image} alt={`Image ${index}`} onClick={() => handleImageClick(index)}  />
                ))}
                {showImageViewer && (
                    <ImageViewer images={images} selectedIndex={selectedImageIndex} onClose={handleCloseImageViewer} />
                )}
                </div>


                
            </section>
        </div>
    );
}

export default FireRiskPrediction;
