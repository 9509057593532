import React, { useState } from "react";
import ImageViewer from "../components/ImageViewer";
import { useEffect } from "react";


function IndegoExpansion() {
    const [showImageViewer, setShowImageViewer] = useState(false);
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);

    // useEffect(() => {
    //     document.body.style.backgroundColor = "#257AAB";  //set the background color
    //     return () => {
    //       document.body.style.backgroundColor = null;
    //     };
    //   }, []);
  
    const images = [];
    for (let i = 1; i <= 5; i++) {
      images.push(`/img/09_IndegoExpansion/${i}.png`);
    }
      
    const handleImageClick = (index) => {
      setSelectedImageIndex(index);
      setShowImageViewer(true);
    };
  
    const handleCloseImageViewer = () => {
      setShowImageViewer(false);
    };
    
    return (
        <div className="project-container">
            <section className="project">
                <div className="project-header">
                    <div className="project-header-title">
                        <div className="contents-line">
                            <div className="line-10"></div>
                        </div>
                        <div className="project-main-title">
                            Evaluating and Forecasting for <br></br>Philadelphia’s Bike Share Expansion
                        </div>
                    </div>
                    <div className="project-header-year">
                        2023
                        <br></br>
                        <div className="proejct-category">
                            Data Analysis : R
                            Web App : React
                        </div>
                    </div>
                </div>
                <div className="project-info">
                    <div className="project-description">
                        <a className="project-link" href="https://indegoexpansion.web.app/">Indego Station Planner Web App</a><br></br>
                        <a className="project-link" href="/html/Indego_BikeShare.html">Link to markdown</a><br></br>
                        <div className="contents-line">
                            <div className="line-10"></div>
                        </div>
                        {images.map((image, index) => (
                            <>
                                <img className="project-image-text" src={image} alt={`Image ${index}`} onClick={() => handleImageClick(index)}  />
                                {index === 0 && (
                                    <p className="project-detail-description">
                                        For over eight years, Indego, Philadelphia's bike share system, has been a significant part of the city's transportation infrastructure, offering over 2,000 bikes across more than 200 stations to residents and visitors. However, a significant concentration of the network is in Center City, leaving surrounding neighborhoods with scattered stations. To address this, the <a href="https://www.phila.gov/departments/office-of-transportation-infrastructure-sustainability/" className="underlineOnHover">Office of Transportation, Infrastructure, and Sustainability (OTIS)</a> partnered with <a href="https://www.bicycletransit.com/" className="underlineOnHover">Bicycle Transit Systems (BTS)</a> in 2022. Together, they launched a multi-year expansion plan for Indego. This plan, guided by over 1,300 responses to the "suggest-a-station" feature, aims to increase station density in areas of high ridership and extend the service area to the outskirts of the city.
                                    </p>
                                )}
                                {index === 1 && (
                                    <p className="project-detail-description">
                                    To assist this expansion plan, our team embarked on a two-pronged project. Initially, we focused on developing machine learning models to predict the average number of rides per week for each 1,000 sq ft grid cell. These cells each host at least one Indego station. To create these models, we used data from over 900,000 rides Indego recorded in 2022, spread across approximately 160 grid cells. The models were designed to consider factors such as socio-economic demographics and the proximity to amenities, while eliminating any potential bias by excluding variables that were specific to Indego, had high multicollinearity, or were tied to racial demographics.
                                    </p>
                                )}
                                {index === 2 && (
                                    <p className="project-detail-description">
                                        Leading the development of the project's second phase, I spearheaded the creation of <a href="https://indegoexpansion.web.app/" className="underlineOnHover">the Station Planner tool</a>. This web application is designed to assist Indego's planners in evaluating proposed station configurations in three key areas: expected ridership, accessibility, and the socio-economic characteristics of the populations served. We incorporated several crucial features into the models that underpin this tool, such as population density, proximity to amenities like bike paths, parks, and transport stations, the number of active stations within a grid cell, and accessibility to various facilities such as grocery stores, jobs, tourist spots, and restaurants.
                                    </p>
                                )}
                                {index === 3 && (
                                    <p className="project-detail-description">
                                        In testing eight different machine learning models, the XGB-Poisson model emerged as the most accurate in predicting ridership. Although it had some difficulty predicting ridership in high-traffic areas and at newer stations on the network's edges, it demonstrated less spatial error across Philadelphia than the other models. Because of its overall accuracy and robustness, we selected the XGB-Poisson model for our final predictions. This model and the Station Planner application I led the development of are now ready to support OTIS and BTS in their ongoing efforts to maintain an accessible, equitable, and optimized bike share network.
                                    </p>
                                )}
                                {index === 4 && (
                                    <p className="project-detail-description">
                                    <a href="https://indegoexpansion.web.app/" className="underlineOnHover">The Station Planner application</a> is a user-friendly platform allowing interactive engagement with the Indego service area. Users can zoom in and out and gain information about existing bike stations by simply hovering over them. They can also explore the current bike share ridership and population distribution in the city using buttons on the application's left side. To customize the network further, users can add new stations by clicking on the map. They can easily correct any mistakes using the reset and delete tools. As they make changes, the application reflects how these affect Indego's performance metrics in real-time. Finally, users can save and download their configurations for future reference, and if they want to revisit these configurations later, they can upload them back into the map. With the Station Planner application, Indego's bike share expansion is ready for optimization and planning by both experts and enthusiastic city residents alike.
                                    </p>
                                )}
                            </>
                        ))}
                    </div>
                    <div className="project-info-credit">
                        UPENN MUSA
                        <br></br>
                        Minwook Kang
                        <br></br>
                        Rebekah Adams
                        <br></br>
                        Aidan Rhianne
                        <br></br>
                        <br></br>
                        INSTRUCTOR
                        <br></br>
                        Michael Fichman<br></br>
                        Matt Harris
                    </div>
                </div>
                <div>
                {/* {images.map((image, index) => (
                    <img className="project-image" src={image} alt={`Image ${index}`} onClick={() => handleImageClick(index)}  />
                ))} */}
                {showImageViewer && (
                    <ImageViewer images={images} selectedIndex={selectedImageIndex} onClose={handleCloseImageViewer} />
                )}
                </div>


                
            </section>
        </div>
    );
}

export default IndegoExpansion;
