import React, { useState } from "react";
import ImageViewerWide from "../components/ImageViewerWide";
import { useEffect } from "react";


function ChildrensPark() {
    const [showImageViewer, setShowImageViewer] = useState(false);
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);

    // useEffect(() => {
    //     document.body.style.backgroundColor = "black";  //set the background color
    //     return () => {
    //       document.body.style.backgroundColor = null;
    //     };
    //   }, []);
  
    const images = [];
    for (let i = 2; i <= 5; i++) {
      images.push(`/img/Research/childrenParks/${i}.jpg`);
    }
      
    const handleImageClick = (index) => {
      setSelectedImageIndex(index);
      setShowImageViewer(true);
    };
  
    const handleCloseImageViewer = () => {
      setShowImageViewer(false);
    };
    
    return (
        <div className="project-container">
            <section className="project">
                <div className="project-header">
                    <div className="project-header-title">
                        <div className="contents-line">
                            <div className="line-10"></div>
                        </div>
                        <div className="project-main-title">
                            Proposal For The Low-used<br></br> Children's Park In Seoul
                        </div>
                    </div>
                    <div className="project-header-year">
                        2015
                        <br></br>
                        <div className="proejct-category">
                            Graduate Thesis <br></br>
                            Project
                        </div>
                    </div>
                </div>
                <div className="project-info">
                    <div className="project-description">
                        {/* <a className="project-link" href="https://sca.seoul.go.kr/seoulhour/site/urbanArch/exhibition/exhibitPast/624">Link to Exhibition</a><br></br> */}
                        {/* <div className="contents-line">
                            <div className="line-10"></div>
                        </div> */}
                        <div>
                            <p className="project-detail-description">
                            In Seoul, there are different types of parks available, ranging from mountainous parks to large parks and small children's parks. These children's parks were established due to the rapid development of Seoul, and their sizes and layouts are not consistent. Additionally, their utilization varies greatly from one park to another. However, due to the decline in birth rates in recent years, these parks are not visited by children anymore. Moreover, they are surrounded by tall buildings, which makes it challenging for them to receive sufficient sunlight.
                            </p>
                            <p className="project-detail-description">
                            Our initial query was about the potential of building a children's park. In terms of area, it would be 62% of Central Park, and constructing the park facilities would be equivalent to the size of five World Trade Center buildings. Along with the modern potential, we were also curious about the energy potential, specifically how much sunlight would be required and how we could measure it. To quantify this, we used the solar power generation dimension, although in hindsight, it might have been more appropriate to consider carbon.
                            </p>
                            <p className="project-detail-description">
                            Although the calculation method we used is not statistically precise, it roughly estimates the power generation potential at 179.06 GWH. We factored in the surrounding buildings, using EPW data from 2015. With this in mind, we propose a new children's park design that maximizes this potential. Using the GENETIC algorithm, we determined the critical point where the structure's height is minimized, yet power generation remains possible. The algorithm recommends a three-dimensional critical height for the structure. The electricity produced here can be utilized for children's play without any cost. Additionally, it is designed as a platform that accommodates public bicycle charging stations, public Wi-Fi, and data servers related to various smart cities.
                            </p>
                        </div>
                    </div>
                    <div className="project-info-credit">
                        HONGIK UNIVERSITY <br></br>
                        Kang Minwook<br></br>
                        <br></br>
                        PROFESSOR<br></br>
                        Yoo Hyunjoon<br></br>
                        Park Saehee            
                    </div>
                </div>
                <div>
                {images.map((image, index) => (
                    <img className="project-image project-image-wide" src={image} alt={`Image ${index}`} onClick={() => handleImageClick(index)}  />
                ))}
                {showImageViewer && (
                    <ImageViewerWide
    images={images}
    selectedIndex={selectedImageIndex}
    onClose={handleCloseImageViewer}
    imageWidth="80%"
  />
                )}
                </div>


                
            </section>
        </div>
    );
}

export default ChildrensPark;
