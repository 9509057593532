import React, { useState } from "react";
import ImageViewerWide from "../components/ImageViewerWide";
import { useEffect } from "react";


function PublicAssets() {
    const [showImageViewer, setShowImageViewer] = useState(false);
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);

    // useEffect(() => {
    //     document.body.style.backgroundColor = "black";  //set the background color
    //     return () => {
    //       document.body.style.backgroundColor = null;
    //     };
    //   }, []);
  
    const images = [];
    for (let i = 1; i <= 3; i++) {
      images.push(`/img/Research/publicAssets/${i}.jpg`);
    }
      
    const handleImageClick = (index) => {
      setSelectedImageIndex(index);
      setShowImageViewer(true);
    };
  
    const handleCloseImageViewer = () => {
      setShowImageViewer(false);
    };
    
    return (
        <div className="project-container">
            <section className="project">
                <div className="project-header">
                    <div className="project-header-title">
                        <div className="contents-line">
                            <div className="line-10"></div>
                        </div>
                        <div className="project-main-title">
                            Analyze municipally-owned real estate <br></br>for efficient use of public space
                        </div>
                    </div>
                    <div className="project-header-year">
                        2019-2020
                        <br></br>
                        <div className="proejct-category">
                            Research <br></br>
                        </div>
                    </div>
                </div>
                <div className="project-info">
                    <div className="project-description">
                        {/* <a className="project-link" href="https://sca.seoul.go.kr/seoulhour/site/urbanArch/exhibition/exhibitPast/624">Link to Exhibition</a><br></br>
                        <div className="contents-line">
                            <div className="line-10"></div>
                        </div> */}
                        <div>
                            <p className="project-detail-description">
                            Our project, known as ZERO Land Price Seoul, aimed to rediscover the potential of underutilized municipal assets in Seoul. As a rapidly expanding city with various urban problems, such as disconnection, low birthrate, aging population, inequality, and regional imbalance, Seoul needs sustainable policies and spatial resources more than ever. To address these challenges, the city is implementing various policies such as public children's centers, public rental housing, and youth spaces. However, the high proportion of land acquisition costs in total project cost poses a significant challenge to creating quality public spaces.
                            </p>
                            <p className="project-detail-description">
                            To tackle this issue, our project analyzed data on over 3,000 assets in Seoul, mapped and visualized urban planning issues, and suggested potential uses for individual sites. We identified 2.12 km2 of potentially usable land, enough to provide tens of thousands of public rental units and over 9,000 public nursery places. As the project manager, I led the comprehensive research to uncover the potential of Seoul's territory and create a roadmap for its future development.
                            </p>
                            <p className="project-detail-description">
                            This project is an extension of a pilot project to utilize underutilized infrastructure through mixed-use development and utilize various underground walking spaces in Seoul for public policy. Our goal was to examine and reveal the potential for further utilization of Seoul's public buildings, which are widely spread throughout the city.
                            </p>
                            <p className="project-detail-description">
                            We developed commercialization plans for high-potential sites, aiming to reduce land acquisition costs and improve the creation of quality public spaces. Our findings were compiled into a report and delivered to the city of Seoul. I also participated in follow-up studies to ensure the implementation of our recommendations.
                            </p>
                        </div>
                    </div>
                    <div className="project-info-credit">
                        URBAN OPERATIONS <br></br>
                        <br></br>
                        Lee Janghwan<br></br>
                        Kang Minwook<br></br>
                        Kim Juhyen<br></br>
                    </div>
                </div>
                <div>
                {images.map((image, index) => (
                    <img className="project-image project-image-wide" src={image} alt={`Image ${index}`} onClick={() => handleImageClick(index)}  />
                ))}
                {showImageViewer && (
                    <ImageViewerWide
    images={images}
    selectedIndex={selectedImageIndex}
    onClose={handleCloseImageViewer}
    imageWidth="50%"
  />
                )}
                </div>


                
            </section>
        </div>
    );
}

export default PublicAssets;
