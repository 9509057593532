import React, { useState } from "react";
import ImageViewer from "../components/ImageViewer";
import { useEffect } from "react";


function VacantLotDetection() {
    const [showImageViewer, setShowImageViewer] = useState(false);
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);

    // useEffect(() => {
    //     document.body.style.backgroundColor = "black";  //set the background color
    //     return () => {
    //       document.body.style.backgroundColor = null;
    //     };
    //   }, []);
  
    const images = [];
    for (let i = 1; i <= 7; i++) {
      images.push(`/img/10_VacantLots/${i}.png`);
    }
      
    const handleImageClick = (index) => {
      setSelectedImageIndex(index);
      setShowImageViewer(true);
    };
  
    const handleCloseImageViewer = () => {
      setShowImageViewer(false);
    };
    
    return (
        <div className="project-container">
            <section className="project">
                <div className="project-header">
                    <div className="project-header-title">
                        <div className="contents-line">
                            <div className="line-10"></div>
                        </div>
                        <div className="project-main-title">
                            Vacant Lot Detection Deep Learning<br></br> Segmentation Model of Philadelphia
                        </div>
                    </div>
                    <div className="project-header-year">
                        2023
                        <br></br>
                        <div className="proejct-category">
                            Data Analysis : Python <br></br>
                            Deep Learning : <br></br>
                            U-Net, MobileNetV2, EfficientNetB0
                        </div>
                    </div>
                </div>
                <div className="project-info">
                    <div className="project-description">
                        <a className="project-link" href="https://drive.google.com/file/d/17kJHUaLjOAE7ebuQxvjsAWRxtdB3zdmn/view?usp=sharing">Link to Full Report</a><br></br>
                        <a className="project-link" href="https://github.com/kmu973/Philadelphia_vacant_lot_detection">Link to Github</a><br></br>
                        <div className="contents-line">
                            <div className="line-10"></div>
                        </div>
                        {images.map((image, index) => (
                            <>
                                <img className="project-image-text" src={image} alt={`Image ${index}`} onClick={() => handleImageClick(index)}  />
                                {index === 0 && (
                                    <p className="project-detail-description">
                                        Our team, consisting of Teresa Chang and Minwook Kang, embarked on an innovative project to identify vacant lots in Philadelphia. We harnessed the power of deep learning models and satellite imagery to target over 40,000 vacant land parcels, as mentioned in the 2021 Philadelphia Land Bank's Strategic Plan. Our primary motivation was to provide city planners and community garden projects with a tool to locate and make efficient use of these unused areas for green space development and other urban planning initiatives. 
                                    </p>
                                )}
                                {index === 1 && (
                                    <p className="project-detail-description">
                                        Data preparation presented a significant challenge due to resolution issues with our initial data sources. We eventually turned to higher-resolution aerial imagery from <a href="https://maps.psiee.psu.edu/ImageryNavigator/" className="underlineOnHover">Penn State University</a> and labeled data from <a href="https://opendataphilly.org/datasets/vacant-property-indicators/" className="underlineOnHover">Vacant Property Indicators</a>. The data was cleaned, preprocessed, and augmented to avoid overfitting and ensure compatibility with our models. We divided the data into manageable 512*512 tiles for visualization and processing.
                                    </p>
                                )}
                                {index === 2 && (
                                    <p className="project-detail-description">
                                        We employed three deep learning models in our study. The first was the U-Net, a convolutional neural network architecture known for its effectiveness in image segmentation. The second was a modified U-Net that used the pre-trained MobileNetV2 as its encoder, providing a more efficient backbone for segmentation tasks. The final model was a U-Net with an EfficientNetB0 backbone, designed to further enhance segmentation performance.
                                    </p>
                                )}
                                {index === 3 && (
                                    <p className="project-detail-description">
                                        Optimization of our models was a major focus, emphasizing computational efficiency and accuracy. We monitored metrics such as Intersection over Union (IoU), Dice coefficient, and F1 score to evaluate our models' performance. We implemented early stopping to prevent overfitting, and the Adam optimizer was used for improved performance and faster convergence.
                                    </p>
                                )}
                                {index === 5 && (
                                    <p className="project-detail-description">
                                        The modified U-Net with the MobileNetV2 encoder, our second model, demonstrated superior results across all metrics. This included accuracy, IoU, Dice Coefficient, and F1 score. It showed excellent generalization capability, indicating that it would perform well on unseen data. The visualization of results further confirmed this model's effectiveness in accurately predicting vacant lots.<br></br>
                                        The model we developed has several practical applications. It can help monitor changes in the total area of vacant lots over time. Even with the limitations in our research scope, we observed a significant increase in vacant lot area from 2018 to 2021. This model could prove instrumental for city planners and community garden projects, enabling efficient identification of potential sites.<br></br>
                                        Our research has opened several avenues for future work. This includes testing the model on other cities with similar urban characteristics, exploring alternative deep learning architectures, and optimizing hyperparameters. We also considered the use of ensemble models and enhanced training and validation processes as potential improvements. Despite the areas for refinement, our study effectively demonstrated the power of deep learning in analyzing urban land use, offering a valuable tool for urban planning and sustainable development.
                                    </p>
                                )}
                                {index === 6 && (
                                    <p className="project-detail-description">
                                        The model we developed has several practical applications. It can help monitor changes in the total area of vacant lots over time. Even with the limitations in our research scope, we observed a significant increase in vacant lot area from 2018 to 2021. This model could prove instrumental for city planners and community garden projects, enabling efficient identification of potential sites.<br></br>
                                        Our research has opened several avenues for future work. This includes testing the model on other cities with similar urban characteristics, exploring alternative deep learning architectures, and optimizing hyperparameters. We also considered the use of ensemble models and enhanced training and validation processes as potential improvements. Despite the areas for refinement, our study effectively demonstrated the power of deep learning in analyzing urban land use, offering a valuable tool for urban planning and sustainable development.
                                    </p>
                                )}
                            </>
                        ))}
                    </div>
                    <div className="project-info-credit">
                        UPENN MUSA
                        <br></br>
                        Minwook Kang
                        <br></br>
                        Teresa Chang
                        <br></br>
                        <br></br>
                        PROFESSOR
                        <br></br>
                        Guray Erus
                    </div>
                </div>
                <div>
                {/* {images.map((image, index) => (
                    <img className="project-image" src={image} alt={`Image ${index}`} onClick={() => handleImageClick(index)}  />
                ))} */}
                {showImageViewer && (
                    <ImageViewer images={images} selectedIndex={selectedImageIndex} onClose={handleCloseImageViewer} />
                )}
                </div>


                
            </section>
        </div>
    );
}

export default VacantLotDetection;
