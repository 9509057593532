import React from "react";
import { Link } from "react-router-dom";
import DataAnalysisGrid from "./DataAnalysisGrid";
import ResearchGrid from "./ResearchGrid";
import DesignGrid from "./DesignGrid";
import Map from "./Map";

function Main() {
  return (
    <>
      <Map />
      <div className="container-mainpage">
        <div className="contents-line">
          <Link to="#Data-Analysis" className="contents-line-text" id="Data-Analysis">
            {" "}
            🞇 Data Analysis / Visualization
          </Link>
        </div>
        <section className="data-image">
          <DataAnalysisGrid />
        </section>
        
        <div className="contents-line">
          <Link to="#Design" className="contents-line-text" id="Design">
            {" "}
            🞦 Design{" "}
          </Link>
        </div>
        <section className="data-image">
          <DesignGrid />
        </section>

        <div className="contents-line">
          <Link to="#Research" className="contents-line-text" id="Research">
            {" "}
            🞑 Research{" "}
          </Link>
        </div>
        <section className="data-image">
          <ResearchGrid />
        </section>


      </div>
    </>
  );
}

export default Main;
