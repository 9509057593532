import React, { useState } from "react";
import ImageViewerWide from "../components/ImageViewerWide";
import { useEffect } from "react";


function MujuPublicPlatform() {
    const [showImageViewer, setShowImageViewer] = useState(false);
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);

    // useEffect(() => {
    //     document.body.style.backgroundColor = "#4B4A4B";  //set the background color
    //     return () => {
    //       document.body.style.backgroundColor = null;
    //     };
    //   }, []);
  
    const images = [];
    for (let i = 1; i <= 1; i++) {
      images.push(`/img/Design/01_Muju/${i}.jpg`);
    }
      
    const handleImageClick = (index) => {
      setSelectedImageIndex(index);
      setShowImageViewer(true);
    };
  
    const handleCloseImageViewer = () => {
      setShowImageViewer(false);
    };
    
    return (
        <div className="project-container">
            <section className="project">
                <div className="project-header">
                    <div className="project-header-title">
                        <div className="contents-line">
                            <div className="line-10"></div>
                        </div>
                        <div className="project-main-title">
                            Muju Public Platform
                        </div>
                    </div>
                    <div className="project-header-year">
                        2020
                        <br></br>
                        <div className="proejct-category">
                            Architecture<br></br>
                            Competition<br></br>
                            Honorable Mention
                        </div>
                    </div>
                </div>
                <div className="project-info">
                    <div className="project-description">
                        <a className="project-link" href="http://kai2002.org/news/11768">Link to Related Site</a>
                        <div className="contents-line">
                            <div className="line-10"></div>
                        </div>
                        <div>
                            <p className="project-detail-description">
                            The Muju Public Platform is an innovative project that combines the parking lot of the Muju Traditional Market with convenient facilities and sports amenities. With the market only open for 24 days of the year, the design aims to capture the daily life of Muju during the remaining 341 days. The parking lot, which makes up 65% of the facility area, is envisioned as a flexible platform that can be utilized for various purposes outside of market hours. The proposal includes a senior club to support productive activities for the elderly, a public lounge for community activities, and a multi-purpose platform for festivals and events.
                            </p>
                            <p className="project-detail-description">
                            Muju's population has stagnated in recent years, with the proportion of elderly people steadily increasing. To address the low-density environment of the city, the senior club on the Bandi Happiness Nuri platform will provide support for productive activities for the elderly. Residents will operate and manage the multi-purpose parking platform and share a public lounge for educational and community activities. The design incorporates the daily life of Muju, with agricultural products forming the center of daily life in the city. The platform aims to reflect the needs of residents and accommodate various small festivals and events that occur throughout the year.
                            </p>
                            <p className="project-detail-description">
                            The parking lot is divided into three spatial areas to respond flexibly to parking demand with large deviations in use. On days when the market is open, all parking lots A, B, and C are used as parking lots. However, on other days, parking lot A is set to permanent parking to respond to low parking demand during normal times, while parking lots B and C can be used for other purposes. The multi-purpose platform can be appropriated for various uses, making it an ideal space for festivals and events. The Muju Public Platform is an innovative project that responds to the needs of Muju's residents and provides a flexible space for community activities.
                            </p>
                        </div>
                    </div>
                    <div className="project-info-credit">
                        URBAN OPERATIONS<br></br>
                        Lee Janghwan<br></br>
                        Kang Minwook <br></br>
                        Kim Juhyun<br></br>
                        <br></br>
                        1990 UAO<br></br>
                        Yoon GeunJoo<br></br>
                        Kim Cheol<br></br>

                    </div>
                </div>
                <div>
                {images.map((image, index) => (
                    <img className="project-image project-image-wide" src={image} alt={`Image ${index}`} onClick={() => handleImageClick(index)}  />
                ))}
                {showImageViewer && (
                    <ImageViewerWide
    images={images}
    selectedIndex={selectedImageIndex}
    onClose={handleCloseImageViewer}
    imageWidth="85%"
  />
                )}
                </div>


                
            </section>
        </div>
    );
}

export default MujuPublicPlatform;
