import React, { useState } from "react";
import { useMap } from "react-leaflet";
import { homeIcon } from "../icons/homeIcon";
import { homeIconHover } from "../icons/homeIconHover";

const HomeButton = ({ position, zoom }) => {
  const map = useMap();
  const [icon, setIcon] = useState(homeIcon);

  const handleClick = () => {
    map.flyTo(position, zoom);
  };

  const handleMouseEnter = () => {
    setIcon(homeIconHover);
  };

  const handleMouseLeave = () => {
    setIcon(homeIcon);
  };

  return (
    <button
      className="leaflet-bar leaflet-control leaflet-control-custom home-button"
      type="button"
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <img src={icon.options.iconUrl} alt="Home" />
    </button>
  );
};

export default HomeButton;
