import React from "react";
import { NavLink } from 'react-router-dom';
import mountainPng from "./../images/mountain.png"

// {({isActive}) => `header-content-text ${isActive ? 'header-content-text-active':""}`}
// {({isActive}) => `nav-link ${isActive? 'nav-link-active':''}`} 

function Header () {
    
    return (
        <>
            <header>
                <div>
                <NavLink to ="/" className= {({isActive}) => `header-content-title ${isActive ? 'header-content-title-active':""} header-content`} >Minwook Kang</NavLink>
                </div>
                {/* <NavLink to ="/">
                    <h1 className="header-title">Minwook Kang</h1>
                </NavLink> */}

                <div>
                    <NavLink to ="/data_analysis" className= {({isActive}) => `header-content-text ${isActive ? 'header-content-text-active':""} header-content`}
>🞇 Data Analysis</NavLink>
                    <NavLink to ="/design" className={({isActive}) => `header-content-text ${isActive ? 'header-content-text-active':""} header-content`}
>🞦 Design</NavLink>
                    <NavLink to ="/research" className={({isActive}) => `header-content-text ${isActive ? 'header-content-text-active':""} header-content`}
>🞑 Research</NavLink>

                    <NavLink to="/about" className={({isActive}) => `header-content-text ${isActive ? 'header-content-text-active':""} header-content`}
>🞭 About</NavLink>
                </div>
            </header>
        </>
    )
}

export default Header;