import React, { useState } from "react";
import ImageViewerWide from "../components/ImageViewerWide";
import { useEffect } from "react";


function AnotherSeoul2() {
    const [showImageViewer, setShowImageViewer] = useState(false);
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);

    // useEffect(() => {
    //     document.body.style.backgroundColor = "black";  //set the background color
    //     return () => {
    //       document.body.style.backgroundColor = null;
    //     };
    //   }, []);
  
    const images = [];
    for (let i = 1; i <= 2; i++) {
      images.push(`/img/Research/anotherSeoul2/${i}.jpg`);
    }
      
    const handleImageClick = (index) => {
      setSelectedImageIndex(index);
      setShowImageViewer(true);
    };
  
    const handleCloseImageViewer = () => {
      setShowImageViewer(false);
    };
    
    return (
        <div className="project-container">
            <section className="project">
                <div className="project-header">
                    <div className="project-header-title">
                        <div className="contents-line">
                            <div className="line-10"></div>
                        </div>
                        <div className="project-main-title">
                            An-other Seoul : Potential Territory
                        </div>
                    </div>
                    <div className="project-header-year">
                        2019-2021
                        <br></br>
                        <div className="proejct-category">
                            Research <br></br>
                        </div>
                    </div>
                </div>
                <div className="project-info">
                    <div className="project-description">
                        <a className="project-link" href="https://sca.seoul.go.kr/seoulhour/site/urbanArch/exhibition/exhibitPast/150">Link to Exhibition</a><br></br>
                        <a className="project-link" href="https://store.seoul.go.kr/cp/goods/goods_view.html?gpart=general&uid=11276">Link to Publication</a>
                        <div className="contents-line">
                            <div className="line-10"></div>
                        </div>
                        <div>
                            <p className="project-detail-description">
                            This research aims to explore new approaches to urban innovation in Seoul, which has one of the highest population densities in the world, under the theme of "Analyzing and Understanding the Present." The focus is on infrastructure, an indispensable element for a city's organic functioning, which currently covers approximately 65% of the city's total land area. This quantitative enormity has the potential to transform the city itself. The exhibition aims to generate interest and initiate discussions on the possibilities of infrastructure as a means to transform Seoul.
                            </p>
                            <p className="project-detail-description">
                            Seoul has reached a point where a reevaluation of its infrastructure is necessary, and a "new infrastructure" needs to be created. This new infrastructure must function as an "architecture of the city," capable of transforming the city based on its unique function as an urban substructure, while also accommodating the programs of various lifestyle SOCs.
                            </p>
                            <p className="project-detail-description">
                            By fostering interest and initiating discussions on infrastructure, this research hopes to create a new Seoul based on innovative and sustainable infrastructure that meets the demands of the city's population and promotes a high quality of life for its citizens.
                            </p>
                        </div>
                    </div>
                    <div className="project-info-credit">
                        URBAN OPERATIONS <br></br>
                        <br></br>
                        Lee Janghwan<br></br>
                        Kang Minwook<br></br>
                        Kim Juhyen<br></br>
                        No Huncheol<br></br>
                    </div>
                </div>
                <div>
                {images.map((image, index) => (
                    <img className="project-image project-image-wide" src={image} alt={`Image ${index}`} onClick={() => handleImageClick(index)}  />
                ))}
                {showImageViewer && (
                    <ImageViewerWide
    images={images}
    selectedIndex={selectedImageIndex}
    onClose={handleCloseImageViewer}
    imageWidth="65%"
  />
                )}
                </div>


                
            </section>
        </div>
    );
}

export default AnotherSeoul2;
