import React, { useState } from "react";
import ImageViewerWide from "../components/ImageViewerWide";
import { useEffect } from "react";


function MunsanLibrary() {
    const [showImageViewer, setShowImageViewer] = useState(false);
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);

    // useEffect(() => {
    //     document.body.style.backgroundColor = "#968A83";  //set the background color
    //     return () => {
    //       document.body.style.backgroundColor = null;
    //     };
    //   }, []);
  
    const images = [];
    for (let i = 1; i <= 1; i++) {
      images.push(`/img/Design/03_Munsan/${i}.jpg`);
    }
      
    const handleImageClick = (index) => {
      setSelectedImageIndex(index);
      setShowImageViewer(true);
    };
  
    const handleCloseImageViewer = () => {
      setShowImageViewer(false);
    };
    
    return (
        <div className="project-container">
            <section className="project">
                <div className="project-header">
                    <div className="project-header-title">
                        <div className="contents-line">
                            <div className="line-10"></div>
                        </div>
                        <div className="project-main-title">
                            Munsan Public Library : Library in the Woods
                        </div>
                    </div>
                    <div className="project-header-year">
                        2021
                        <br></br>
                        <div className="proejct-category">
                            Architecture <br></br>
                            Competition <br></br>
                            Honorable Mention
                        </div>
                    </div>
                </div>
                <div className="project-info">
                    <div className="project-description">
                        <a className="project-link" href="https://www.masilwide.com/paju1124/">Link to Related Site</a>
                        <div className="contents-line">
                            <div className="line-10"></div>
                        </div>
                        <div>
                            <p className="project-detail-description">
                            'The Library in the Woods' is a project aimed at designing a library in the cultural park that surrounds the site. The park's center comprises hard surfaces like basketball and soccer fields, which we plan to leverage to create an integrated library that doesn't infringe on the natural environment. We propose four planning strategies to achieve this objective. Firstly, we limit the placement of the library building to the paved areas of the soccer field, basketball court, and open-air concert hall to minimize encroachment into the natural green area. Secondly, we spread the surface area of the building to maximize contact with nature. Thirdly, we plan to increase the density of trees in the park through additional planting schemes, enhancing the natural green areas. Fourthly, we aim to connect the existing walking path in the park to the library driveway to integrate the library and park into one.
                            </p>
                            <p className="project-detail-description">
                            Regarding the architectural plan, the library's design contrasts its main entrance from the parking lot with its entry to the park, positively responding to its surroundings. The park-facing elevation features extensive terraces to increase ground connection, with a canopy over the terraces to create different layers of light between the exterior and interior spaces. Through this design, we aimed to multilayer the library's interaction with nature, creating different hierarchies of forest feelings for readers.
                            </p>
                            <p className="project-detail-description">
                            The park plan is divided into two main areas by the library: an active space with a parking lot and multi-purpose field, and a static space surrounded by trees. The library's design features a thick boundary towards the active space and a multi-layered boundary towards the static green space to integrate them. Additional plantings along the perimeter of the park enhance the sense of enclosure, and trails within the park extend the existing trails around the perimeter, naturally integrating residents' familiar patterns of park use with the new Munsan Base Library and Cultural Park.
                            </p>
                        </div>
                    </div>
                    <div className="project-info-credit">
                        URBAN OPERATIONS <br></br>
                        Lee Janghwan<br></br>
                        Kang Minwook<br></br>
                        <br></br>
                        STUDIO IN LOCO <br></br>
                        Kang Seunghyun<br></br>
                        Kim Naun <br></br>
                        Lim Hyungjun
                    </div>
                </div>
                <div>
                {images.map((image, index) => (
                    <img className="project-image project-image-wide" src={image} alt={`Image ${index}`} onClick={() => handleImageClick(index)}  />
                ))}
                {showImageViewer && (
                    <ImageViewerWide
    images={images}
    selectedIndex={selectedImageIndex}
    onClose={handleCloseImageViewer}
    imageWidth="85%"
  />
                )}
                </div>


                
            </section>
        </div>
    );
}

export default MunsanLibrary;
