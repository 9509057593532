import React, { useState } from "react";
import ImageViewer from "../components/ImageViewer";
import { useEffect } from "react";



function TodStudy() {
    const [showImageViewer, setShowImageViewer] = useState(false);
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);

    // useEffect(() => {
    //     document.body.style.backgroundColor = "#061c40";
    //     return () => {
    //       document.body.style.backgroundColor = null;
    //     };
    //   }, []);
  
    const images = [];
    for (let i = 1; i <= 11; i++) {
      images.push(`/img/01/${i}.png`);
    }
      
    const handleImageClick = (index) => {
      setSelectedImageIndex(index);
      setShowImageViewer(true);
    };
  
    const handleCloseImageViewer = () => {
      setShowImageViewer(false);
    };
    
    return (
        <div className="project-container">
            <section className="project">
                <div className="project-header">
                    <div className="project-header-title">
                        <div className="contents-line">
                            <div className="line-10"></div>
                        </div>
                        <div className="project-main-title">
                            TOD Study in Newyork
                        </div>
                    </div>
                    <div className="project-header-year">
                        2022
                        <br></br>
                        <div className="proejct-category">
                            Data Analysis : R
                        </div>
                    </div>
                </div>
                <div className="project-info">
                    <div className="project-description">
                        <a className="project-link" href="/html/Assignment2_TOD_Minwook_KANG.html">Link to markdown</a>
                        <div className="contents-line">
                            <div className="line-10"></div>
                        </div>
                        <div className="project-detail-description">
                            <p className="project-detail-description">
                            The purpose of this research is to investigate whether New Yorkers are willing to pay higher rents to live near subway stations, or whether the dense existing transportation network in New York City is the primary reason for high subway usage. The study used data from subway infrastructure that spanned Manhattan, Brooklyn, Queens, and the Bronx. Transit-Oriented Development (TOD) areas were defined as being within half a mile of a subway station, and the study compared data from TOD and Non-TOD areas between 2009 and 2019 to identify trends in population, rent, and income. The research revealed that New Yorkers are indeed willing to pay higher rents to live near subway stations, and that increasing the density of cities and improving public transportation services through TOD development can drive urban growth.
                            </p>
                            <p className="project-detail-description">
                            The study found that the density of cities around TOD areas has been increasing, with a higher population density observed in the TOD area compared to the Non-TOD area. Although there was no significant difference in rent between TOD and Non-TOD areas in 2009, rents in TOD areas increased steeply over time, and the research showed that White and Bachelor populations were willing to pay higher rent. However, there was an increase in the percentage of vacant housing, suggesting that government policies may be necessary to subsidize high rent increases. Further research is needed to analyze the cause of lower rent in the Bronx TOD area.
                            </p>
                            <p className="project-detail-description">
                            The research demonstrated that New Yorkers prefer to live near subway stations and are willing to pay higher rents to do so. However, the tendency for vacancy rates to increase in TOD areas may pose another housing problem in the future, particularly as the proportion of student populations living in these areas increases. The study also suggested that the Bronx could be a suitable area for future TOD development, with a stable balance of income and rent for residents. Overall, the findings suggest that TOD development has the potential to drive urban growth, but careful consideration must be given to the impact on housing affordability and vacancy rates.
                            </p>
                        </div>
                    </div>
                    <div className="project-info-credit">
                        UPENN MUSA
                        <br></br>
                        Minwook Kang
                        <br></br>
                        <br></br>
                        PROFESSOR
                        <br></br>
                        Elizabeth Delmelle<br></br>
                        Michael Fichman<br></br>
                        Matt Harris
                    </div>
                </div>
                <div>
                {images.map((image, index) => (
                    <img className="project-image" src={image} alt={`Image ${index}`} onClick={() => handleImageClick(index)}  />
                ))}
                {showImageViewer && (
                    <ImageViewer images={images} selectedIndex={selectedImageIndex} onClose={handleCloseImageViewer} />
                )}
                </div>


                
            </section>
        </div>
    );
}

export default TodStudy;
