import React, { useState } from "react";
import ImageViewer from "../components/ImageViewer";
import { useEffect } from "react";


function RiskPrediction() {
    const [showImageViewer, setShowImageViewer] = useState(false);
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);

    // useEffect(() => {
    //     document.body.style.backgroundColor = "#061c40";  //set the background color
    //     return () => {
    //       document.body.style.backgroundColor = null;
    //     };
    //   }, []);
  
    const images = [];
    for (let i = 1; i <= 11; i++) {
      images.push(`/img/05/${i}.png`);
    }
      
    const handleImageClick = (index) => {
      setSelectedImageIndex(index);
      setShowImageViewer(true);
    };
  
    const handleCloseImageViewer = () => {
      setShowImageViewer(false);
    };
    
    return (
        <div className="project-container">
            <section className="project">
                <div className="project-header">
                    <div className="project-header-title">
                        <div className="contents-line">
                            <div className="line-10"></div>
                        </div>
                        <div className="project-main-title">
                         Automobile Theft Risk Prediction in Chicago
                        </div>
                    </div>
                    <div className="project-header-year">
                        2022
                        <br></br>
                        <div className="proejct-category">
                            Data Analysis : R
                        </div>
                    </div>
                    
                </div>
                <div className="project-info">
                    <div className="project-description">
                        <a className="project-link" href="/html/Geospatial_Risk_Prediction.html">Link to markdown</a>
                        <div className="contents-line">
                            <div className="line-10"></div>
                        </div>
                        <div className="project-detail-description">
                            <p className="project-detail-description">
                            The objective of this project is to develop a model for predicting the geospatial risk of car theft in Chicago using available crime data and other relevant variables. However, the existing crime data may be incomplete and generated in a biased manner, which can lead to inaccurate predictions and ineffective prevention strategies. To address this, the analysis used various techniques such as selecting as much relevant data as possible from different areas of the city, working with law enforcement officials and experts to understand and address any biases in the data, and comparing models that considered hotspot risk factors to models that only used selected risk factors.
                            </p>
                            <p className="project-detail-description">
                            The analysis used Poisson regression to model a count outcome like automobile theft, given its relatively rare occurrence, and Local Moran's I to check the degree of clustering at the regional scale. The analysis found that the use of hotspot risk factors led to a higher degree of generalization in the models, but did not show good results in capturing crimes in high-risk areas. This indicates the need for further improvement and the potential use of a model that predicts risk for events such as fires that do not bias real data collection.
                            </p>
                            <p className="project-detail-description">
                            The analysis also presented various visualizations, such as maps and graphs, to better understand the geospatial risk of car theft in Chicago. For example, Figure 9-1 shows the location of the 2019 automobile theft above the Kernel Density Map and Hotspot Risk Prediction Map, which were created using data from 2018. Each map is divided into five categories according to the degree of risk, where 5th is the highest risk and 1st is the lowest risk. Figure 10-1 is a graph showing how many crimes were captured according to the risk categories in each model. These visualizations provide insights into the distribution and patterns of automobile theft in Chicago and can inform policy and prevention strategies.
                            </p>
                        </div>
                    </div>
                    <div className="project-info-credit">
                        UPENN MUSA
                        <br></br>
                        Minwook Kang
                        <br></br>
                        <br></br>
                        PROFESSOR
                        <br></br>
                        Elizabeth Delmelle<br></br>
                        Michael Fichman<br></br>
                        Matt Harris
                    </div>
                </div>
                <div>
                {images.map((image, index) => (
                    <img className="project-image" src={image} alt={`Image ${index}`} onClick={() => handleImageClick(index)}  />
                ))}
                {showImageViewer && (
                    <ImageViewer images={images} selectedIndex={selectedImageIndex} onClose={handleCloseImageViewer} />
                )}
                </div>


                
            </section>
        </div>
    );
}

export default RiskPrediction;
