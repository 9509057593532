import React, { useState, useEffect } from 'react';

const DesignGrid = () => {
  const [gridItems, setGridItems] = useState([]);

  useEffect(() => {
    const items = [
      {
        img: '/img/Design/03_Munsan/1f.jpg',
        title: 'Munsan Public Library',
        route: 'Munsan-public-library'
      },
      {
        img: '/img/Design/02_Jeonju/1.jpg',
        title: 'Jinju Public Library',
        route: 'Jinju-public-library'
      },
      {
        img: '/img/Design/01_Muju/1.jpg',
        title: 'Muju Public Platform',
        route: 'Muju-public-platform'
      },
      {
        img: '/img/Design/04_Shizuoka/1.jpg',
        title: 'Shizuoka Airport',
        route: 'Shizuoka-airport'     
      },

      // Add more items as needed
    ];

    setGridItems(items);
  }, []);

  return (
    <div className="image-grid">
      {gridItems.map((item, index) => (
          <a href = {`/${item.route.replace(/ /g, '-')}`} className="grid-item" key={index} >
            <div className="image-border">
               <img className="image-grid-images" src={item.img} alt={`image ${index + 1}`}/>
            </div>
            <div className="image-title">{item.title}</div>
          </a>
      ))}
    </div>
  );
};

export default DesignGrid;
