export const research = {
  type: "FeatureCollection",
  features: [
    {
      type: "Feature",
      properties: {
        id: 1,
        name: "An-other Seoul : River and Mountains",
        year: "2019-2021",
        location: "Seoul, South Korea",
        route: "An-other-Seoul",
      },
      geometry: {
        type: "Point",
        coordinates: [126.98786544046348, 37.53420096285005],
      },
    },
    {
      type: "Feature",
      properties: {
        id: 2,
        name: "Analyze municipally-owned real estates",
        year: "2019-2020",
        location: "Seoul, South Korea",
        route: "Public-assets",
      },
      geometry: {
        type: "Point",
        coordinates: [126.99786544046348, 37.52420096285005],
      },
    },
    {
      type: "Feature",
      properties: {
        id: 3,
        name: "Proposal For The Childrens Park",
        year: 2015,
        location: "Mapo-gu, Seoul, South Korea",
        route: "childrens-park"
      },
      geometry: {
        type: "Point",
        coordinates: [126.92595752615891, 37.55723006143032],
      },
    },
    {
      type: "Feature",
      properties: {
        id: 4,
        name: "An-other Seoul : Potential territories",
        year: "2019-2021",
        location: "Seoul, South Korea",
        route: "An-other-Seoul-2"
      },
      geometry: {
        type: "Point",
        coordinates: [126.97786544046348, 37.54420096285005],
      },
    },
  ],
};
